/******************Theme CSS******************/
/******************Variables******************/
:root {
    --main-color-light: #f09b57;
    --main-color-dark: #eb812a;
    --color-info: #ffffff;
}

/**************************************************/
/******************Common CSS******************/
body {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    background-color: #f5f5f5;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-auto {
    margin: 0 auto;
}

.mb-40{
    margin-bottom: 40px;
}

.cursor-pointer {
    cursor: pointer;
}

.main-color {
    color: var(--main-color-dark) !important;
}

.line-height-3 {
    line-height: 3;
}

.error-details {
    margin-top: 2em;
}

.font-lg {
    font-size: 1.5em;
}

.font-xs {
    font-size: 0.7em;
}

.fw-600 {
    font-weight: 600;
}

.height-30 {
    height: 30px;
}

.white-conatiner {
    background-color: #ffffff;
    box-shadow: 0px 5px 12px 0 rgba(0, 0, 0, 0.2);
}

.mr-20 {
    margin-right: 20px;
}

.btn-add, .btn-save, .btn-refresh, .btn-download, .btn-add-new, #fileUploader .dx-button-content, .btn-custom-add {
    border: 1px solid var(--main-color-dark);
    background-color: #FFFFFF;
    color: var(--main-color-dark);
    padding: 0;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.btn-txt-custom-add{
    padding: 7px 25px;
}

.btn-add > div.btn-text, .btn-add > div.btn-icon , #fileUploader .dx-button-content {
    padding: 8px 14px !important;
}

.btn-add > div.btn-icon {
    background-color: var(--main-color-dark);
}

.btn-download, .btn-add-new {
    /* width: 166px; */
    height: 36px;
}

.btn-refresh, .btn-save {
    /* width: 148px; */
    height: 36px;
}

.btn-download > div.btn-txt-download {
    padding: 7px 19.5px !important;
}

.btn-add-new > div.btn-txt-add-new {
    padding: 7px 30px !important;
}

.btn-download > div.btn-icon-downolad, .btn-add-new > div.btn-icon-add-new, .btn-icon-custom-add {
    background-color: var(--main-color-dark);
    padding: 7.3px 12px !important;
}

.btn-refresh > div.btn-txt-refresh {
    padding: 7px 21px !important;
}

.btn-save > div.btn-icon-save {
    background-color: var(--main-color-dark);
    padding: 7.3px 14px !important;
}

.btn-refresh > .btn-icon-refresh {
    background-color: var(--main-color-dark);
    padding: 7.3px 12px !important;
}

.btn-save > div.btn-txt-save {
    padding: 8px 33.5px !important;
}


 .vertical-align-sub {
    vertical-align: sub !important;
}

.vertical-align-super{
    vertical-align: super !important;
}

.w-92 {
    width: 92px;
}

.w-60 {
    width: 60% !important;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50{
    margin-top: 50px;
}

svg {
    fill: currentColor;
}

.line-height-2{
    line-height: 2;
}

.line-height-40 {
    line-height: 40px;
}

.pl-14 {
    padding-left: 14px;
}

.fw-500 {
    font-weight: 500;
}

.pt-6 {
    padding-top: 6px;
}

.font-12 {
    font-size: 12px;
}

.height-40 {
    height: 40px;
}

.pl-15 {
    padding-left: 15px;
}

.pt-5px {
    padding-top: 5px !important;
}

.pt-6px {
    padding-top: 6px !important;
}

.mt-15px {
    margin-top: 15px !important;
}

.mt-21px {
    margin-top: 21px !important;
}

.mt-192px {
    margin-top: 192px !important;
}

.mt-31px {
    margin-top: 31px !important;
}

.mt-8px {
    margin-top: 8px !important;
}

.mt-174px {
    margin-top: 174px !important;
}

.mt-17px {
    margin-top: 17px !important;
}
.p-21px{
    padding: 21px;
}

.pt-17px{
    padding-top: 17px;
}
.pb-19px{
    padding-bottom: 19px;
}
.overflow-auto{
    overflow-x: hidden;
    overflow-y: auto;
}
/**************************************************/
/*****************Loader Component*****************/
.loader-conatiner {
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0.3);
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.loader {
    border: 10px solid #e6e6e6;
    border-top: 10px solid var(--main-color-light);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/**************************************************/
/****************Header Css Start*****************/
.header {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    display: block;
    height: 60px!important;
    box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
    transition: left .30s;
    transition-timing-function: ease;
    z-index: 1;
}

.header.expanded {
    left: 211px;
}

.header.collapsed {
    left: 102px;
}

.header .list-inline {
    line-height: 55px;
}

.header .list-inline li {
    padding: 20px;
}

.header .list-inline li:hover {
    background-color: rgba(0, 0, 0, .05)
}

.list-inline li {
    display: inline;
}

.header .dropdown {
    position: absolute;
    width: 45px;
    right: 44px;
    top: 0;
    bottom: 0;
    display: flex;
    align-content: center;
}

.custom-dropdown {
    background-color: transparent !important;
    border: none;
    line-height: 45px;
    color: #ffffff;
    /*padding-right: 83px;*/
    box-shadow: none !important;
    display: flex;
    align-content: center;
    bottom: 0;

}

.email-attachment-dropdown {
    background-color: transparent !important;
    border: none;
    line-height: 45px;
    color: #000000;
    /*padding-right: 83px;*/
    box-shadow: none !important;
    display: flex;
    align-content: center;
    bottom: 0;
    padding-top: 0px;

}

.email-attachment-dropdown.dropdown-toggle::after {
    color: var(--main-color-dark);
    display: none;
    align-self: center;
}

.email-attachment .btn-secondary:hover {
    color: #000000;
    background-color: #5a6268;
    border-color: #545b62;
    text-decoration: underline;}

    .email-attachment .dropdown-item:active {
        color: #000000;
        text-decoration: none;
    }
    .email-attachment .show .btn-secondary.dropdown-toggle {
        color: #000;
        background-color: #545b62;
        border-color: #4e555b;
    }

.custom-dropdown.dropdown-toggle::after {
    color: var(--main-color-dark);
    display: flex;
    align-self: center;
}

.header-menu-icon {
    padding: 20px 39px 26px 21px;
}

.header-vertical-line {
    width: 1px;
    height: 38px;
    background-color: #fab04a;
    top: 12px;
    bottom: 10px;
    left: 74px;
}

.notification-icon {
    right: 105px;
    top: 17px;
}

.header-search {
    left: 100px;
    top: 13px;
    right: 50%;
}

.header-search .search-input {
    border: none;
}

.header-search .search-input:focus {
    box-shadow: none;
}

.header-search .search-input::placeholder {
    color: #cccccc;
}

.header-search .search-icon-div {
    background: transparent;
    border: none;
    color: #cccccc;
    padding-top: 0.7em;
}

/*************************************************/

/********************Schema Form CSS starts*****************/
.item-form > hr {
    padding-top: 25px;
    margin: 0;
}

.item-form > span {
    font-size: 20px;
}

.item-form > .form-group > label {
    padding: 0;
}

.item-form > .form-group {
    margin-bottom: 8px;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    color: #B7B7B7;
}

.form-control, .form-control:focus {
    border: 1px solid #cccccc !important;
    border-width: 0 0 1px 0 !important;
    border-radius: 0 !important;
    padding: 0 0 5px 0;
    opacity: 0.9;
    font-weight: 500 !important;

}

.form-control:focus {
    border-color: transparent !important;
    box-shadow: transparent !important;
    outline: 0 !important;

}

/*********************************************************/

/*************** Left Navigation CSS *********************/
.brand-div {
    padding-bottom: 25px;
}

.brand-name {
    height: 60px;
    padding: 9px 32px 9px 31px;
    box-shadow: 8px 5px 12px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;

}

.brand-logo {
    border-right: 1px solid #eb812b;
    height: 38px;
    width: 179px;
    padding-top: 8px;
}

.left-navigation.expanded .brand-name img {
    width: 144px;
    height: 26px;
}

.left-navigation.collapsed .brand-name img {
    width: 25px;
    height: 26px;
}

.left-navigation.collapsed .brand-name {
    padding-top: 17px !important;
}

.setting {
    height: 34px;
    width: 68px;
    box-shadow: 0 11px 9.9px 6.1px rgba(0, 0, 0, 0.06);
    background-color: #7d7d7d;
    position: absolute;
    right: 0;
    bottom: 20px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.setting > ul > li {
    line-height: 35px !important;
}

.setting > ul > li:hover, .setting > ul > li.active {
    background-color: #eb812a;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.left-navigation {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    box-shadow: 0 11px 9.9px 6.1px rgba(0, 0, 0, 0.06);
    transition: width .30s;
    transition-timing-function: ease;
}

.left-navigation.expanded {
    width: 211px;
}

.left-navigation.collapsed {
    width: 101px;
}

.left-sub-navigation {
    position: fixed;
    top: 61px;
    bottom: 0;
    left: 103px;
    width: 240px;
    box-shadow: 0 11px 9.9px 6.1px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    z-index: -1;
    padding: 0 10px;
    letter-spacing: 1.1px;
}

.left-navigation ul, .left-sub-navigation ul {
    list-style: none;
}

.left-navigation ul {
    bottom: 20px;
    padding-left: 0;
    font-weight: 500;
    color: #737884;
}

.left-sub-navigation > ul {
    padding-top: 20px;
}

.left-navigation ul li, .left-sub-navigation ul li {
    cursor: pointer;
    list-style-position: inside;
}

.business-menu-list > li, .business-menu-list-collapsed > li {
    font-size: 14px;
    font-weight: 500;
    color: #7d7d7d;
    margin-top: 10px;
}

.business-menu-list > li {
    margin-bottom: 20px;
}

.business-menu-list-collapsed > li {
    margin-bottom: 25px;
}

.left-sub-navigation ul {
    padding-left: 0;
}

.left-sub-navigation-list {
    padding: 12px 23px 12px 14px !important;
    overflow-y: auto;
    text-overflow: ellipsis;
}

.left-sub-navigation-list > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 12px 0;
    line-height: 34px;
}

.left-sub-navigation ul li span.title {
    font-weight: 600;
    letter-spacing: 1.1px;
    color: #333333;
}

.left-navigation ul li:hover, .left-navigation-list-item > li > ul > li:hover, .left-navigation-list-item > li > ul > li.active {
    color: #f09b57;
}

.left-navigation-list-item {
    cursor: pointer;
    line-height: 40px;
    padding-left: 48px;
    list-style-position: inside;

}

.main-div {
    margin: -6.5px 11px 0 12px;
    padding: 7px 9px;
}

.left-navigation-li-active .main-div {
    border-radius: 17px;
    background-image: linear-gradient(to bottom, #f09b57, #f09b57), linear-gradient(to top, #6bd91a, #ffffff);
    color: #ffffff;
}

.left-navigation-li-active {
    color: var(--main-color-dark);
}

.left-navigation-icon {
    padding: 0 10px 0 30px
}

.setting-icon {
    padding: 0 10px;
    color: #ffffff;
}

.left-navigation-child-menu {
    line-height: 30px;
}

.left-navigation-child-menu.Collapsed {
    list-style: none;
    color: #7f879a;
}

.left-navigation-child-menu > li {
    line-height: 30px !important;
}

.left-navigation.expanded .left-navigation-child-menu {
    padding: 0 21px 0 29px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-navigation.collapsed .left-navigation-child-menu {
    text-align: center;
    margin-top: 5px;
}

.left-navigation-child-menu.Collapsed .left-navigation-li-active {
    color: var(--main-color-dark);
}

.left-navigation-dropdown, .left-navigation-child-dropdown {
    background-color: transparent !important;
    border: none !important;
    color: #7f879a;
}

.left-navigation-dropdown:hover,
.left-navigation-dropdown:active,
.left-navigation-dropdown:focus {
    background-color: transparent;
    border: none;
    color: #eb812b;
    box-shadow: none !important;
}

.left-navigation-child-dropdown:hover,
.left-navigation-child-dropdown:active,
.left-navigation-child-dropdown:focus {
    color: #0e1a35 !important;
    box-shadow: none !important;
    font-weight: bold;
}

.left-nav-header {
    line-height: 40px;
    height: 40px;
    margin: 10px;
    padding-left: 20px;
}

.main-list-item i {
    line-height: 40px;
}

/* .main-list-item-collapsed.active{
    border-left: 9px solid var(--main-color-dark);
} */
.sub-Item-list li:hover {
    color: var(--main-color-dark);
}

.title.active {
    color: var(--main-color-dark);
}

.sub-Item.active {
    color: #ffffff;
    background-color: var(--main-color-dark);
    border-radius: 20px;
    position: relative;
    left: -10px;
    padding: 0 10px;
}

/**********************************************************/

/***********************Right Container CSS****************/
.right-container {
    position: fixed;
    top: 61px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: -1;
    background-color: #f5f5f5;
    transition: left .30s;
    transition-timing-function: ease;
}

.right-container.expanded {
    left: 211px;
}

.right-container.collapsed {
    left: 102px;
}
 .right-container.expanded-subNavigation {
      left: 343px;
   }

.page-container, .tab-page-container {
    padding: 25px 35px 11px 35px;
    height: 100%;
}

.page-header {
    margin-bottom: 20px;
}

.page-header .title {
    font-size: 20px;
    position: relative;
    top: -3px;
    width: 300px;
    float: right;
}

.page-header .backIcon{
    float:left;
    cursor: pointer;
}

.page-header .description {
    color: #898888;
    padding-top: 1px;
}

.right-content {
    margin: 20px 0;
}

.form-container {
    width: 90%;
}

.view-card {
    position: relative;
    height: 71px;
    white-space: normal;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
    padding: 6px 16px;
    max-width: 311px;
    flex: 1 0 21%;
}

.view-card-img {
    position: absolute;
    bottom: 20px;
    left: 20px;
    height: 71px;
    width: 68px;
}

.container-div {
    background-color: #ffffff;
   padding: 23px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
}

.document-grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 11px;
}

.document-grid-title {
    font-size: 18px;
    color: #666666;
    letter-spacing: 0.2px;
}

.document-overview-container {
    height: 6em;
    margin-bottom: 20px;
}

.card-div {
    height: 100%;
}

.dashboard-grid {
    margin-top: 8px;
}

.collapse-text {
    font-size: 12px;
    font-weight: 500;
}

/**********************************************/

/**********DevExpress controls Overrides***********/
.dx-form-group-caption {
    color: #eb812b;
    font-weight: 500;
    line-height: 0.63;
    letter-spacing: 0.2px;
    font-size: 16px !important;
}

.dx-form-group-content {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 10px 0 30px 0 !important;
    margin-bottom: 20px !important;
}

#documentGrid .dx-datagrid-header-panel, #documentGrid .dx-toolbar , .view .dx-datagrid-header-panel, .view .dx-toolbar {
    background-color: #f5f5f5;
    padding: 0 !important;
}

.dx-datagrid-headers {
    border-bottom: 2px solid var(--main-color-dark) !important;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.2);

}

.dx-datagrid-headers, .dx-datagrid-rowsview, .dx-command-edit {
    background-color: #ffffff;
}
.custom-form .dx-field-item:not(.no-margin){
    margin: 0 35px;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link {
    color: #666666 !important;
}
.dx-datagrid-edit-form-item.dx-first-col.dx-last-col{
    margin: 0 18px 0 22px ;
}
.dx-toolbar .dx-toolbar-items-container {
    height: 50px !important;
}

.dx-toolbar-before .dx-item {
    padding-top: 12px !important;
}

.dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #cccccc !important;
    letter-spacing: 0.1px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-weight: 600 !important;
    letter-spacing: 0.9px;
    line-height: 2.17 !important;
}

.dx-datagrid-filter-row {
    height: 40px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-row > td {
    padding: 10px 19px !important;
    font-size: 12px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-filter-row > td {
    padding: 0 19px !important;
    height: 40px !important;
}

.dx-datagrid-filter-row .dx-menu-item-content {
    padding-top: 6px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input, .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
    padding-left: 32px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-texteditor .dx-texteditor-input, .dx-datagrid .dx-editor-with-menu, .dx-datagrid .dx-editor-with-menu, .dx-datagrid-container .dx-editor-with-menu {
    height: 40px;
}

.dx-command-select{
    height: 42px;
}

.dx-datagrid .dx-row > td , .dx-select-checkboxes-hidden > td{
    padding: 10px 19px !important;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: 0.1px;
}

.dx-datagrid {
    color: #333333 !important;
}

.dx-icon {
    padding: 0 10px 0 3px;
}

.dx-field-item-label-text {
    font-size: 13px;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(51, 51, 51, 0.7) !important;
    line-height: normal !important;
}

.dx-texteditor-input {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 1.86 !important;
    letter-spacing: 1.1px !important;
    text-align: left;
}

.custom-form .dx-layout-manager .dx-field-item:not(.dx-last-col):not(.no-margin), .custom-form .dx-layout-manager .dx-field-item:not(.no-margin) {
    margin: 0 35px !important;
}

.dx-datagrid-edit-popup .dx-layout-manager .dx-field-item:not(.dx-last-col), .dx-datagrid-edit-popup .dx-layout-manager .dx-field-item:not(.dx-first-col) {
    margin: 0 18px 0 22px !important;
    padding-right: 2px !important;
}

.dx-datagrid-edit-popup .dx-layout-manager .dx-field-item:not(.dx-first-col) {
    padding-left: 8px !important;
}

.dx-datagrid-edit-popup .dx-toolbar-before .dx-item {
    padding-top: 0 !important;
}

.dx-form-group-with-caption .dx-form-group-content {
    margin-top: 12px !important;
}

.dx-datagrid-search-panel .dx-icon-search {
    left: unset !important;
    right: 5px;
}

.dx-datagrid-search-panel .dx-texteditor-input {
    background-color: #ffffff;
    border-radius: 20px;
    padding-left: 15px !important;
    padding-right: 30px !important;
}

.dx-placeholder {

    font-size: 14px !important;
    font-weight: 500;
    line-height: 1.86;
    letter-spacing: 1.1px;
    padding-bottom: 5px;
    top: -6px !important;
}

.dx-datagrid-search-panel .dx-placeholder {
    font-family: 'Open Sans', sans-serif;
    margin-left: -2px;
    font-weight: normal !important;
    font-style: normal !important;
    line-height: 28px !important;
    letter-spacing: normal !important;
    text-align: left;
    font-stretch: normal !important;
    color: rgba(51, 51, 51, 0.61);
    left: 1px !important;
}

.dx-field-item-label-location-top {
    padding: 16px 0 3px;
    margin-bottom: 0 !important;
}

.dx-popup-content {
    padding: 24px 10px !important;
}

.dx-popup-title {
    background-color: var(--main-color-dark) !important;
    color: #ffffff !important;
}

.dx-checkbox {
    margin-top: 9px;
}

.nested-grid .dx-toolbar-after {
    top: -53px;
    right: -22px;
    padding-right: 10px;
}

.nested-grid .dx-datagrid-header-panel, .nested-grid .dx-toolbar {
    height: 0;
}

.nested-grid .dx-form-group-content {
    padding-top: 0 !important;
    max-height: 300px;
}

.nested-grid .dx-datagrid-addrow-button, .nested-grid .dx-datagrid-toolbar-button .dx-icon {
    color: var(--main-color-dark) !important;
}

.nested-grid .dx-icon-add {
    position: absolute;
    top: 7px;
    right: 0;
}

.nested-grid .dx-button-has-icon.dx-button-has-text .dx-button-content {
    padding: 6px 30px 6px 5px;
    font-weight: 500;
}

.nested-grid .dx-datagrid-addrow-button {
    margin-left: 20px;
}

.nested-grid .dx-freespace-row {
    display: none;
}

.nested-grid .dx-datagrid-rowsview.dx-empty {
    height: 20px !important;
}

.nested-grid .dx-scrollable-wrapper {
    max-height: 300px;
}

.dx-datagrid-edit-popup .dx-scrollable {
    position: relative;
    top: -6px;
}

 .dx-scrollbar-vertical .dx-scrollable-scroll{
    width: 12px!important;
}
.dx-scrollbar-horizontal.dx-scrollbar-hoverable{
    height: 12px!important;
}
.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 13px!important;
} 
.dx-datagrid-edit-popup .dx-toolbar-label > div {
    margin: 0 -21px;
    padding-left: 30px;
}

.dx-datagrid-edit-popup .dx-toolbar-button > div {
    padding-right: 15px;
}

.btn-nested-group {
    margin-right: -10px;
    height: 36px !important;
}

.btn-popup-save {
    width: 80px;
}

.dx-datagrid-column-chooser .dx-popup-title {
    background-color: #ffffff !important;
    color: var(--main-color-dark) !important;
    text-align: center !important;
}

.dx-datagrid-column-chooser .dx-popup-title.dx-toolbar {
    height: 46px;
}

.dx-datagrid-column-chooser .dx-button-has-icon .dx-icon {
    width: 12px !important;
    height: 12px !important;
    font-size: 14px !important;
}

.dx-datagrid-column-chooser .dx-popup-title.dx-toolbar .dx-toolbar-before {
    padding-right: 0px !important;
}

.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title .dx-toolbar-label {
    font-size: 15px !important;
}

.dx-datagrid-column-chooser .dx-toolbar-before {
    width: 100%;
}

.dx-datagrid-column-chooser .dx-overlay-content {
    /*transform: translate(70%, 349px) !important;*/
}

.dx-datagrid-column-chooser .dx-toolbar-before .dx-item {
    padding-top: 0px !important;
}

.dx-datagrid-column-chooser .dx-popup-title.dx-toolbar {
    padding: 0 20px !important;
}

.dx-datagrid-column-chooser .dx-popup-content {
    padding: 10px 21px !important;
}

.dx-datagrid-header-panel .dx-texteditor.dx-editor-underlined:after, .dx-datagrid-header-panel .dx-texteditor.dx-editor-underlined.dx-state-hover:after {
    border-bottom: none !important;
}

.dx-datagrid-header-panel .dx-texteditor.dx-state-focused:before, .dx-datagrid-header-panel .dx-texteditor.dx-state-active:before {
    border-bottom: transparent !important;
}

.dx-button.dx-state-hover {
    background-color: transparent !important;
}

.dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 5px 0 7px !important;
}

.dx-field-item {
    padding-bottom: 0 !important;
}

.dx-lookup-field {

    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.86;
    letter-spacing: 1.1px;
    text-align: left;
    color: #333333;
}

.dx-lookup.dx-editor-underlined .dx-lookup-field {
    padding: 0% !important;
    padding-bottom: 10px !important;
}

.dx-command-edit .dx-icon {
    padding: 0 3px !important;
}

.dx-datagrid-content .dx-data-row:hover {
    box-shadow: 0px 3px 18px 0 rgba(0, 0, 0, 0.27);
    color: #eb812a;
}

.dx-selection.dx-row:hover > td {
    color: #eb812a !important;
}

.dx-selection.dx-row > td {
    background-color: transparent !important;
}

.dx-datagrid-search-panel .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 5px 0 10px !important;
}

.dx-searchbox.dx-editor-underlined .dx-texteditor-input {
    padding: 7px 10px !important;
}

.dx-form-group-with-caption > .dx-form-group-content {
    border: none !important;
}

.dx-datagrid-column-chooser .dx-treeview-item-content {
    font-size: 12px;
    font-weight: 500;
}

.dx-datagrid-column-chooser .dx-overlay-content {
    width: 235px !important;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25) !important;
}

.dx-datagrid-column-chooser .dx-toolbar-after {
    width: 30px !important;
}

.dx-closebutton {
    background-color: transparent !important;
}

.dx-icon-close {
    color: #ffffff !important;
}

.dx-lookup-popup-search .dx-overlay-content {
    height: 350px !important;
}

.dx-datagrid-header-panel .dx-show-clear-button .dx-icon-clear:before {
    background-color: #FFFFFF;
}

.dx-datagrid-header-panel .dx-show-clear-button .dx-icon-clear, .dx-datagrid-header-panel .dx-searchbox .dx-icon-search {
    background-color: #FFFFFF !important;
    color: #7F7F7F;
}

.dx-datagrid-header-panel .dx-texteditor-buttons-container {
    padding-right: 4px;
}

.dx-datagrid-column-chooser-button .dx-button-content {
    padding-top: 9px !important;
    padding-right: 15px !important;
}

.dx-datagrid-column-chooser-button .dx-button-content .dx-icon {
    width: 22px !important;
    height: 18px !important;
    vertical-align: center;
}

.dx-datagrid-bottom-load-panel{
    width: calc(100vw - 70px);

}

.dx-datagrid-content.dx-pointer-events-target .dx-loadindicator-icon{
    display: none;
}

.login-for-group .dx-overlay-wrapper.dx-invalid-message {
    left: 6px;
    text-align: right;
    top: -62px;
}

.login-for-group .dx-overlay-wrapper.dx-invalid-message .dx-overlay-content {
    border: 1px solid;
    border-radius: 6px;
    padding: 7px 18px;
    transform: none !important;
    background: #ffffff;
}

.login-for-group .dx-invalid.dx-texteditor .dx-texteditor-buttons-container:after {
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.dx-datagrid-edit-popup .dx-popup-bottom {
    height: 84px !important;
    position: inherit;
    bottom: -9px;
    right: 10px;
}

.nested-grid .dx-editor-cell .dx-texteditor-container, .nested-grid .dx-editor-cell .dx-texteditor-input, .nested-grid .dx-command-edit.dx-command-edit-with-icons{
    height: 25px !important;
    line-height: 25px !important;
}

.nested-grid .dx-command-edit.dx-command-edit-with-icons{
    padding: 0 !important;
}

.nested-grid .dx-widget.dx-visibility-change-handler{
    max-height: inherit;
}

.dx-scrollable-content{
    width: 100%;
}

#fileUploader .dx-fileuploader-input-wrapper .dx-button{
    float:none;
    width: 186px;
}

#fileUploader .dx-button{
    box-shadow: none;
}

#fileUploader .dx-fileuploader-files-container, #fileUploader .dx-fileuploader-file-container{
    padding: 0;
    box-shadow: none;
}
.dx-fileuploader-file-container .dx-fileuploader-file{
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

.grid-save-icon, .grid-cancel-icon{
    width: 12px;
    height: 12px;
}

.custom-disable > .dx-field-item-content > .dx-state-disabled{
    opacity: unset;
    pointer-events: all;
}

.inline-list .dx-scrollview-content{
    display: flex;
    flex-wrap: wrap;
}

.inline-list .dx-list-item{
    display: unset;
    width: 200px;
    height: 35px;
    flex-grow: 1;
    flex-shrink: 0;
}

.inline-list .dx-list-next-button{
    width: 200px;
    flex-grow: 1;
    flex-shrink: 0;
}

.inline-list .dx-list-item-content > div{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
}

.inline-list .dx-list-select-all{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 35px;
    flex-grow: 1;
    flex-shrink: 0;
}

.inline-list .dx-list-select-all .dx-checkbox{
    line-height: 30px;
}

.inline-list .dx-list-select-all-label{
    line-height: 25px;
    padding: 0 17px;
    margin-top: 0;
}

.inline-list .dx-list-select-all:after{
    height: 0;
}

.inline-list .dx-list-search{
    width: 250px;
    margin-left: 16px;
    margin-bottom: 20px;
}

.inline-list .dx-list-item:first-of-type{
    margin-top: 0;
}

.inline-list .dx-list:not(.dx-list-select-decorator-enabled), .inline-list .dx-list-item.dx-list-item-selected{
    background-color: unset !important;
}

.inline-radio-group .dx-widget{
    display: flex;
    flex-wrap: wrap;
}

.inline-radio-group .dx-field-item-label{
    margin-bottom: 15px !important;
}

.inline-radio-group .dx-radiobutton{
    margin-right: 15px !important;
}

.inline-list .dx-button-default{
    margin-top: 5px !important;
}

.inline-list .dx-button-default.dx-state-hover{
    background-color: var(--main-color-dark) !important;
}

.dx-datagrid-content{
    user-select: none;
}
/**********************************************/

/*********BootStrap CSS overrides*************/
.text-muted {
    color: #898888 !important;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:visited {
    background: #ffffff !important;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid var(--main-color-dark) !important;
    color: var(--main-color-dark) !important;
}

.btn-outline-primary {
    color: var(--main-color-light) !important;
    border-color: var(--main-color-light) !important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:visited {
    background: transparent linear-gradient(to top left, var(--main-color-dark), var(--main-color-light)) !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
    box-shadow: none !important;
        }
        
.dx-button-content:hover, .dx-button-content:focus, .dx-button-content:active, .dx-button-content:visited {
    background: transparent linear-gradient(to top left, var(--main-color-dark), var(--main-color-light)) !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
    box-shadow: none !important;
}



.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.text-primary{
    color: var(--main-color-dark) !important;
}

/*********************************************/
/*********Login Page*************/
.login-page-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
}

.login-left-container, .login-right-container {
    top: -40px;
    bottom: -35px;
    width: 457px;
    border-radius: 10px;
    background-image: url("../img/login-background.png");
    color: #ffffff;
    z-index: 1;

}

.login-left-container {
    left: 50px;
    animation: slide-in 1.2s backwards;
}

.login-right-container {
    right: 50px;
    animation: slide-out-signup 1.2s backwards;
}


@keyframes slide-in {
    from {
        left: 400px;
    }

    to {
        left: 50px;

    }
}

@keyframes slide-in-signup {
    from {
        left: 400px;
    }

    to {
        left: 0;

    }
}

.login-right-slide {
    animation: slide-out 1.2s backwards;
}

.signup-right-slide {
    animation: slide-in-signup 1.2s backwards;
}

@keyframes slide-out {
    from {
        right: 500px;
    }

    to {
        right: 0;
    }
}

@keyframes slide-out-signup {
    from {
        right: 400px;
    }

    to {
        right: 50px;
    }
}

.login-right-container {
    right: 50px;

}

.login-welcome-text, .login-welcome-subtext {
    line-height: 1.56;
}

.login-welcome-text {
    font-size: 18px;
}

.login-welcome-subtext {
    width: 376px;
    height: 33px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.58;
    letter-spacing: 1px;
    margin: 23px 34px 0 35px;
}

.bullet, .bullet-signup {
    height: 40px;
    background-image: linear-gradient(to bottom, #eb7b22, #eb7b22);
    line-height: 40px;
    color: #ffffff;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.6px;
}

.bullet {
    border-radius: 40px 0 0 40px;
    padding: 0 0 0 23px !important;
    margin-top: 40px;
    width: 126px;
    padding: 0 0 0 13px;
}

.bullet-signup {
    border-radius: 0 40px 40px 0;
    margin-top: 35px;
    width: 145px;
    padding: 0 0 0 22px;
}

.login-form {
    margin: 65px 50px 0 49px;
}

.signup-form {
    margin: 38px 54px 0 49px;
}

.signup-form .dx-field-label {
    overflow: inherit;
}

.login-for-group {
    margin-bottom: 18px !important;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7b7b7b;
}

.login-for-group .dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 3px 0 10px !important;
}

.login-page-container .dx-texteditor-container {

    color: rgba(51, 51, 51, 0.9);
    line-height: 1.75;
}

.login-page-container .dx-field-label {
    color: #7b7b7b;
    font-size: 13px;
}

.forgot-password {

    font-size: 13px;
    text-align: left;
    color: #666666;

}

.text-right {
    text-align: right !important;

}

.login-button, .login-sub-button, .register-button {
    width: 287px;
    height: 40px;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px !important;
    line-height: 40px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.login-sub-button {
    margin-top: 10px !important;
}

.login-sub-button > button {
    width: 89px;
    height: 36px;
}

.fb-button {
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
    margin-right: 9px;
}

.fb-button:hover {
     /* background: #ffffff !important;
    color: #3a589b !important;
    border-color: #3a589b !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.fb-button:active, .fb-button:focus {
     /* background: #ffffff !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.google-button {
    /* color: #e14928 !important;
    border-color: #e14928 !important;
    margin: 0 !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.google-button:hover {
     /* background: #ffffff !important;
    color: #e14928 !important;
    border-color: #e14928 !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.google-button:active, .google-button:focus {
    /* color: #e14928 !important;
    border-color: #e14928 !important;
    margin: 0 !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.twitter-button {
     /* color: #1b95e0 !important;
    border-color: #1b95e0 !important;
    margin-left: 9px;
    margin-right: 0 !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
    margin-left: 9px;
    margin-right: 0 !important;
}

.twitter-button:hover {
     /* background: #ffffff !important;
     color: #1b95e0 !important;
    border-color: #1b95e0 !important; */
    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}

.twitter-button:active, .twitter-button:focus {
     /* background: #ffffff !important; */
    /* color: #1b95e0 !important;
    border-color: #1b95e0 !important; */
    margin-left: 9px;
    margin-right: 0 !important;

    color: #bababa !important;
    border-color: #bababa!important;
    background: #ffffff !important;
}


.test {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500 !important;
    letter-spacing: 1.1px;
    text-align: center;
    color: #666666;

}

.test span {
    display: inline-block;
    position: relative;
}

.test span:before,
.test span:after {
    content: "";
    position: absolute;
    height: 10px;
    border-bottom: 1px solid #cccccc;
    top: 0;
    width: 123px;
}

.test span:before {
    right: 17px;
    margin-right: 15px;
}

.test span:after {
    left: 17px;
    margin-left: 15px;
}

.forgot-password-form, .success-message-form {

    font-size: 20px;
    color: #333333;
}

.forgot-password-form {
    margin-top: 59px;
}

.success-message-form {
    margin-top: 196px;
}

.forgot-password-text, .success-message-text {
    font-weight: 500 !important;
    color: #898888 !important;
    margin-top: 8px;
    margin-bottom: 5px;
}

.success-message-text {
    width: 302px;
    margin-left: 44px;
}

.resend-OTP {
    font-weight: 500 !important;
    color: #7b7b7b;
    margin-top: 7px;
}

.login-page-container input:-webkit-autofill {
    border: transparent;
    box-shadow: inset 0 0 0px 9999px white;
}

.login-page-container input:focus, .login-page-container input:-webkit-autofill:focus {
    border-color: #66afe9;
    box-shadow: inset 0 0 0px 9999px white;
}


/*********************************************/

/*************SubChildSection CSS******************/
.child-section-card-header {
    border-bottom: 1px solid var(--main-color-light);
}

.list-with-link > li {
    color: var(--main-color-dark);
}

.list-with-link > li:hover {
    text-decoration: underline;
}

/*********************************************/

/***************Grid Wrapper css*********************/
.dot-menu-grid {
    font-size: 16px !important;
}

/************************************************/

/**********Dashboard Menu Item *****************/
.dashboard-menu-item-text {
    font-size: 12px;
    white-space: nowrap;
}

.dashboard-menu-item-number {
    font-size: 29px;
    color: #2e3134;
}

.dashboard-menu-item-line {
    width: 36px;
    height: 5px;
    object-fit: contain;
    border-radius: 1.6px;
    background-image: linear-gradient(354deg, #f4813f, #fab04a);
}

/**********************************************/

/***********Scrollbar CSS*************/
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #d7d7d7;
    border-radius: 4px;
}

/**********************************************/


/**************Custom Form ********************/
.custom-lookup {
    height: 30px !important;
}

.custom-form-title {
    font-size: 20px;
    position: relative;
    line-height: 36px;
}

.document-container {
    margin: 50px 35px 40px 35px;
    border-bottom: 1px solid #cccccc;
}

.document-column {
    padding: 0 19px 0 21px;
    margin-bottom: 21.5px;
}

.document-icon {
    margin-top: 6px;
}

.document-label {
    color: rgba(51, 51, 51, 0.7);
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: bold;
}

.document-text {
    font-size: 16px;
    letter-spacing: 1.2px;
    line-height: 1.59;
}

.border-div {
    background-color: #999999;
    opacity: 0.86;
    width: 1px;
    margin-right: 20px;
    margin-bottom: 20.5px;
    min-height: 45px;
}

/**********************************************/


/****************************************** Tab Control ******************************************/
.tab-header {
    background-image: url("../img/header-background.png");
    background-size: 100% 100%;
    height: 154px;
    padding: 25px 32px 0 32px;
    flex-shrink: 0;
}

.tab-title {
    width: 206px;
    color: #ffffff;
    text-align: center;
    line-height: 51px;
    letter-spacing: 0.2px;
    font-size: 16px;
}

.tab-title:hover {
    background-color: #e9e9e9;
    color: var(--main-color-dark);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.tab-title.active {
    background-color: #f6f6f6;
    color: var(--main-color-dark);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/*************************************************************************************************/

/*********************************Buttons ***********************************************/
.btn-outline-info {
    color: var(--color-info);
    border: 1px solid var(--color-info);
}

.btn-outline-info:hover, .btn-outline-info:active {
    color: var(--main-color-dark);
    background-color: var(--color-info) !important;
    border: 1px solid var(--color-info) !important;
}

.btn-outline-info.focus, .btn-outline-info:focus {
    box-shadow: none !important;

}

/******************************************************************************************/

/******************settings AND dev-Console****************************/
.developer-console {
    height: 125px;
    font-weight: 500;
    text-align: left;
    background-image: url("../img/header-background.png");
    background-size: 100% 100%;
    padding: 25px 32px 0 32px;
}

.developer-console .title{
        font-size: 20px;
    }

.devConsole-option-card,.settings-option-card  {
    width: 257px;
    height: 136px;
    border-radius: 3px;
    box-shadow: 0px 4px 9px 0 rgba(0, 0, 0, 0.28);
    background-color: #ffffff;
    text-align: center;
    margin: 0 21px 21px 0;
}


.devConsole-card-img,.settings-card-img {
    padding: 20px 89px;
    color: #333333;

}
 .devConsole-card-text,.settings-card-text {
    font-size: 16px;
    letter-spacing: 1.2px;
    color: #333333;
    border: 1px solid #a6a6a6;
    border-width: 1px 0 0;
    height: 38px;
    line-height: 38px;
    margin: 0 22px;
}

.settings {
    position: fixed;
    top: 61px;
    bottom: 0;
    left: 103px;
    width: 100%;
}

.settings-options {
    padding:32px;
}
/*************************/

/**********form-card***************/

.form-card{
    height: 90%;
    margin: 31px;
    width: 100%;
}
.form-card-content >div{
    padding: 21px;
    height: 100%;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.28);
    background: #ffffff;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
    min-width: 200px;
}

.card-content-img{
    margin-bottom: 20px;
}
.card-content-text,.card-content-text-top,.card-content-text-bottom,.status-title{
    font-size: 14px;
    margin-bottom: 20px;
}
.status-title{
    color: var(--main-color-dark);
}
.card-content-text-top{
    padding: 10px 0;
}
.content-text-bottom{
    font-size: 12px;
    letter-spacing: 1.2px;
}
.tag-control-item{
    padding: 5px 20px;
    border-radius: 15px;
    background:#ffffff;
    border: 1px solid #c2c2c2;
    margin: 10px 0 0 0;
    flex: 0 0 45%;
}
.status-form{
    height: 17%;
    background:transparent;
}
.status-card{
    height: 100%;
    width: 100%;
    background: #ffffff;
    padding: 15px;

}
.status-card-img{
        position: relative;
        top: 40%;
        margin-right: 10px;
        height: 129px;
}
.dashed-connecting-line{
    height: 84%;
    width: 1px;
    border: 1px dashed;
    border-width: 0 1px 0 0;
    position: relative;
    left: 9px;
}
.status-form:last-child .dashed-connecting-line{
    display: none;
}

.form-divider{
    border-top: 1px solid #c2c2c2;
    margin: 15px 0;
}

.form-accordion .dx-accordion{
    background-color: transparent;
}

.form-accordion .dx-accordion-wrapper{
    display: flex;
    flex-wrap: wrap;
}

.form-accordion .dx-accordion-item{
    flex-grow: 1;
    margin: 0 20px 20px 0;
     width: 30%; 
    /*max-width: 25%;*/
    /*flex-shrink: 1;*/
}

.form-accordion .dx-accordion-item:nth-child(3n){
    margin: 0 0 20px 0;
}

.form-accordion .dx-accordion-item:last-child{
    margin: 0;
}

.form-accordion .dx-accordion-item-opened > .dx-accordion-item-title{
    height: initial;
}

.form-accordion .dx-accordion-item-title{
    background-color: var(--main-color-dark);
    color: #ffffff;
    font-weight: 600;
    padding: 8px 24px;
    min-height: 48px;
}

.form-accordion .dx-accordion-item-title:before{
    color: #ffffff;
}

.form-accordion .dx-accordion-item-opened > .dx-accordion-item-title:before{
    top: 8px;
}

.form-accordion .dx-accordion-item-body{
    font-size: inherit;
}

.d-flex .imgBg{
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
}


.d-flex .profileImg{
    /* width: 149px;
    height:  131px; */
    margin: 0 auto;
   
}

.overflow-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-header{
    line-height: 60px;
    margin-bottom: 20px;
    background-color: var(--main-color-dark);
}

.pt-25{
    padding-top: 25px;
}

.pb-11{
    padding-bottom: 11px;
}

.pl-35{
    padding-left: 35px;
}

.pr-35{
    padding-right: 35px;
}

.font-22{
    font-size: 22px;
}

.font-16{
    font-size: 16px;
}

.vertical-align-text-top{
    vertical-align: text-top;
}
/*********************************/

.tab-page-container{
    padding-top: 60px;
}

.tab-header-button{
    height: 36px;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(105, 113, 119, 0.15);
    background-color: #ffffff;
    color: #eb812a;
    font-size: 14px;
    font-weight: 500;
}


/***************** Form Controls *******************************/
.form-button .dx-button{
    background-color: #FFFFFF;
    border: 1px solid var(--main-color-light);
    color: var(--main-color-light);
    box-shadow: none;
}
/***************************************************************/

.thank-you-div-container{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #53cfeb;
}
.thank-you-div{
    background: url("../img/referral-thank-you.png") no-repeat fixed center;
    background-size: contain;
}

.dx-item-content .mt-30{
    margin-top: 30px !important;
}

.dx-item-content .mt-45{
    margin-top: 45px !important;
}

.headerSearch{
    background: #f0f0f0!important;
    border-radius: 20px;
    width:58%!important;
    padding: 3px 10px;
    margin-left: 7%;
}
.headerSearch button{
    background: transparent!important;
}



.customeSearch{
    background: #ffffff;
    border-radius: 20px;
    width:42%;
    padding: 3px 10px;
    margin-left: 7%;
}

.customeSearch .dx-texteditor.dx-editor-underlined{
    border: 0!important;
    width: 80%;
    float: left;
    margin-left: 20px;
    margin-right: 20px;
}

.customeSearch .closebtn {
    position: absolute;
    font-size: 35px;
    cursor: pointer;
    color: #acacac!important;
    background-color: rgba(255, 255, 255, 0);
    padding: 0px 15px 0 0;
    float: right;
    height: 38px;
    margin-top: -6px;
    font-weight: lighter;
  }
  
  .customeSearch .closebtn:hover {
    color:rgb(68, 68, 68)!important;
  } 

.customeSearch button img{
    width: 15px;
}


  .customeSearch button {
    float: left;
    /* padding: 12px 12px 0 20px; */
    background: #fff;
    font-size: 20px;
    border: none;
    cursor: pointer;
}

.chatButton{
    right: 150px;
    top: 12px;
    font-size: 27px;
    color: #eb812a;
    cursor: pointer;
}

.chatPanel{
    right: 0;
    width: 0px;
    height: 100vh;
    /* border: 1px solid #818181; */
    background: #333333;
    z-index: 999;
    background-color: #ffffff;
    -webkit-box-shadow: 0 11px 9.9px 6.1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 11px 9.9px 6.1px rgba(0, 0, 0, 0.06);
    -webkit-transition: width .30s;
    -o-transition: width .30s;
    transition: width .30s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.chatPanelShow {
    width: 360px;
}

.chatPanelHide {
  width: 0px;
}

.chatPanel .header .Close{
  background: url('./../../assets/img/cross.png');
  width: 24px;
  height: 24px;
  float:right;
  font-size: 25px;
  margin-top: 19px;
  margin-left: 11px;
  margin-right: 15px;
  cursor: pointer;
}

.chatPanel .header .dx-icon-refresh{
    width: 24px;
    height: 24px;
    float:right;
    font-size: 25px;
    margin-top: 19px;
    margin-left: 11px;
    margin-right:0;
    cursor: pointer;
  }




.chatPanel .layoutsize{
  height:90%;
  width:100%;
}


.chatPanel .header{
    position: relative;
    top: 0;
    right: 0;
    background-color: #eb812a;
    display: block;
    height: 60px!important;
    -webkit-box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
    -webkit-transition: left .30s;
    -o-transition: left .30s;
    transition: left .30s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    z-index: 1;
}

.chatPanel .chatTitle{
    float: left;
    font-size: 15px;
    margin-top: 22px;
    margin-left: 15px;
    margin-right: 11px;
    color:#ffffff;
    font-weight: bold;
}

.chatPanel .css-djy7c2:not(.from-user), [data-css-djy7c2]:not(.from-user), .css-djy7c2[data-simulate-notfromuser], [data-css-djy7c2][data-simulate-notfromuser] {
    background: White;
    border: solid 0 #E6E6E6;
    border-radius: 2px;
    color: Black;
}

.chatPanel .css-1apvbjj > button, [data-css-1apvbjj] > button {
    font-family: 'Calibri', 'Helvetica Neue', 'Arial', 'sans-serif';
    font-size: inherit;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
    border-radius: 4px;
    border: solid 1px #eb812a!important;
    color: #eb812a!important;
}

.chatPanel .css-1apvbjj > button:hover, [data-css-1apvbjj] > button:hover{
    background-color:#f3b17b!important;
    color: #ffffff!important;
}

.chatPanel .css-1vieo9r .ac-pushButton, [data-css-1vieo9r] .ac-pushButton {
   
    border-style: solid;
    border-width: 1px;
    border: solid 1px #eb812a!important;
    color: #eb812a!important;
    font-weight: bold;
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
}

.chatPanel .css-1vieo9r .ac-pushButton:hover, [data-css-1vieo9r] .ac-pushButton:hover{
    background-color:#f3b17b!important;
    color: #ffffff!important;
}

.chatPanel .css-1r4fa22, [data-css-1r4fa22] {
    padding-left: 18px;
    padding-right: 5px;
}

.chatPanel .css-1s8geyi, [data-css-1s8geyi] {
    color: #767676;
    font-family: 'Calibri', 'Helvetica Neue', 'Arial', 'sans-serif';
    font-size: 80%;
    padding-top: 5px;
    padding-left: 13px;
}

.chatPanel .css-1tdb3h1 > img, [data-css-1tdb3h1] > img{
    padding: 7px;    
}

.chatPanel .css-1dgbgmu, [data-css-1dgbgmu]{
    background-color: #eb812a;
    height: 35px;
    width: 35px;
}



.chatPanel .css-bo8nme, [data-css-bo8nme] {
    height: 36px;
    overflow: hidden;
    padding: 6px;
    width: 28px;
}

/* .chatPanel .css-hgucfj > .content > .row > .bubble{
    border-radius: 10px 10px 10px 0; 
} */

.items {
	overflow: hidden; /* simple clearfix */
}
.items .item {
  float: left;
  width: 30.9%;
  box-sizing: border-box;
  background: #000000;
  color: #171e42;
  padding: 10px;
  margin: 0 20px 20px 0;
}

/***************Terms and conditions***************/
.terms-and-condition-container{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.terms-and-condition-header-container{
    /*padding: 5px;*/
    border-bottom: 1px solid #c2c2c2;
}
/***************************************************/
/***************************************************/
.expanded .custom-nested-container{
    left: 211px;
}

.custom-nested-container{
    position: fixed;
    left: 102px;
    right: 0;
    top: 60px;
    bottom: 0;
    background-color: #f5f5f5;
    z-index: 3;
}

.btn-padding{
    padding: 0 25px;
}

.custom-form .dx-form-group-with-caption{
    padding-left: 0 !important;
}

.custom-nested-form{
    height: calc(100vh - 160px);
}

.schema-nested-form{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #f5f5f5;
    z-index: 3;
}

.colOne{
    /* width: 49%;   
    float: left;
    margin-right: 2%;
    position: relative;
    white-space: normal;
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px; */
}

.colOne .topGraph{
	height:200px;
    padding: 10px;
}

.colOne .topShadow{
	height:218px;
	box-shadow: 0px -3px 2px rgba(0,0,0,0.3);
	-moz-box-shadow:0px -3px 4px rgba(0,0,0,0.3);
	-webkit-box-shadow:0px -3px 4px rgba(0,0,0,0.3);
    -o-box-shadow:0px -3px 4px rgba(0,0,0,0.3);
    padding: 10px;
}
.colOne .selectionBox {
 padding: 7px;
}

.colOne .selectionBox ul{
    padding: 0;
}

.colOne .selectionBox ul li{
    list-style: none;
    float: left;
    width: 33%;
    margin: 10px 0;
}

.colOneInstallBase .selectionBox ul li{
    list-style: none;
    width: 33%;
    margin: 10px 0;
}



#progressbar0 .dx-progressbar-range {
    background-color: #d273e0;
    border-radius: 8px!important
  }
#progressbar1 .dx-progressbar-range {
    background-color: #96ecf2;
    border-radius: 8px!important
  }
#progressbar2 .dx-progressbar-range {
    background-color: #e3a16f;
    border-radius: 8px!important
  }
#progressbar3 .dx-progressbar-range {
    background-color: #e3a16f;
    border-radius: 8px!important
  }
#progressbar4 .dx-progressbar-range {
    background-color: #80ad49;
    border-radius: 8px!important
  }

  .dx-progressbar-container{
      height: 8px !important;
      background-color: #f0f0f0 !important;
      border-radius: 8px !important;
}

.colOne .selectionBox ul li .dot_a{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #5ce1e6;
    float: left;
    margin-right: 10px;
}


.colOne .selectionBox ul li .dot_b{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #44bbe3;
    float: left;
    margin-right: 10px;
}


.colOne .selectionBox ul li .dot_c{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #5592cf;
    float: left;
    margin-right: 10px;
}

.colOne .selectionBox ul li .dot_d{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #6968ab;
    float: left;
    margin-right: 10px;
}

.colOne .selectionBoxNew ul li{
    list-style: none;
    float: left;
    width: 41%;
    margin: 10px;

}

.selectionBoxNew .rangeGraph{
    list-style: none;
    float: left;
    width: 45%;
    margin: 10px;
}

.selectionBoxNew .progre {
    float: right;
    right: -26px;
}


.colOne .selectionBoxNew{
    margin-top: 30px;
}


.colOne .selectionBoxNew svg{
    margin-top: -33px;
}

.colOne .selectionBoxNew .progre{
    float: right;
    right: -26px;
}



.hdrTitle{
    border-bottom: 1px solid #000000;
    vertical-align: middle;
    font-size: 16px;
    padding:6px 0;
    
}

.hdrTitle span{
    font-size: 20px;
    vertical-align: middle;
    padding-right: 8px;
    font-weight: bold;
}

.colTwo{
    width: 49%;
    float: left;
    /* background: #333333; */
    position: relative;
    white-space: normal;
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    padding: 6px 16px;
}

.colTwo .hdrTitle{
    vertical-align: middle;
    font-size: 16px;
    padding:6px 0;
}

.colTwo .cardsCustome{
    padding: 15px;
    padding-right: 0;
}

.colTwo .cardsCustome .smallTitle{
    vertical-align: middle;
    font-size: 13px;
    padding: 6px;
    font-weight: bold;
    color: #fff;
}

.colTwo .cardsCustome .smallTitle span{
    padding-bottom: 3px;
    border-bottom: 2px solid #ffffff;
}

.colTwo .cardsCustome .reportContent{
    font-weight: bold;
    font-size: 26px;
    padding: 10px;
    text-align: center;
    color: #ffffff;
    margin-top: 8px;
}


.colTwo .cardsCustome .cardReportsType1{
    background-color: #62b1b5;
    background-image: linear-gradient(to bottom right,#62b1b5, #99eef2);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}

.colTwo .cardsCustome .cardReportsType2{
    background-color: #d98b91;
    background-image: linear-gradient(to bottom right,#d98b91, #fcccd0);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}

.colTwo .cardsCustome .cardReportsType3{
    background-color: #d073de;
    background-image: linear-gradient(to bottom right,#d073de, #e5d3e8);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}

.colTwo .cardsCustome .cardReportsType4{
    background-color: #7ba5c9;
    background-image: linear-gradient(to bottom right,#7ba5c9, #487599);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}


.colTwo .cardsCustome .cardReportsType5{
    background-color: #bfe38f;
    background-image: linear-gradient(to bottom right,#bfe38f, #7eab48);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}

.colTwo .cardsCustome .cardReportsType6{
    background-color: #ebc4a7;
    background-image: linear-gradient(to bottom right,#ebc4a7, #de9157);
    width: 30.66%;
    min-height: 100px;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
}

.colThree{
    width: 49%;
    float: left;
    margin-top: 16px;
    position: relative;
    white-space: normal;
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    padding: 6px 16px;
}

.colOne .selectionBoxNew .donutchart-track {
    fill: transparent;
    stroke: #e1e3e6; 
    stroke-width: 10px;
}

.colOne .selectionBoxNew .donutchart-indicator {
    fill: transparent;
    /* stroke: #009688; */
    stroke-width: 10px;
    stroke-linecap: round;
    stroke-dasharray: 0 10000;
    -webkit-transition: stroke-dasharray .3s ease;
    -o-transition: stroke-dasharray .3s ease;
    transition: stroke-dasharray .3s ease;
    -webkit-box-shadow: 1px 0px 3px #888888;
    box-shadow: 1px 0px 3px #888888;
    stroke-dasharray: 175.929, 251.327;
}

.tab-pane.active{
    color: #333333;
}

.mt-35{
    margin-top: 35px !important;
}

.form-list .dx-list-item:first-of-type{
    margin-top: 15px;
}

.form-list .dx-list-item{
    margin-bottom: 15px;
}

.form-list .dx-item-content{
    padding: 0 !important;
}

.attachment-container{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.customer-form-tab .dx-tabpanel .dx-tabs, .customer-form-tab .dx-tabpanel .dx-tab{
    background-color: #ffffff !important;
}

.dashboard-Selection .dx-dropdowneditor-icon{
      color: var(--main-color-dark) !important;
}

.dashboard-Selection .dx-texteditor-input{
    font-size: 20px !important;
}

.dashboard-Selection .dx-texteditor.dx-editor-underlined:after{
    border: 0;
}

.dashboard-option-menu, .dashboard-option-menu-last-item{
    margin: 0 !important;
    border-top: 1px solid #c2c2c2 !important;
    border-left: 1px solid #c2c2c2 !important;
    border-right: 1px solid #c2c2c2 !important;
}

.dashboard-option-menu-last-item{
    border-bottom: 1px solid #c2c2c2 !important;
}

.dashboard-option-menu .dx-list-item-content, .dashboard-option-menu-last-item .dx-list-item-content{
    padding: 10px !important;
    font-size: 14px;
}

/*Fix for removing validation from Lookup popover*/
.dx-popup-content .dx-lookup-validation-message{
    display: none !important;
}

.autoComplateDataContainer .autoComplateSearchData{
    float: left;
    font-size: 12px;
    width: 180px;
    margin: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.autoComplate-container{
    margin-left: -10px;
}

.autoComplate-container .dx-list-item-content{
    padding: 0!important;
}
.autoComplate-container .dx-item{
    padding: 5px 0!important;
    border-bottom: 1px solid #ddd !important;
}

.autoComplate-container .dx-item:first-child{
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 1000;
    width: 500px;
    -webkit-box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
}

.autoComplate-container .dx-item:nth-child(2){
    margin-top: 24px;
}

.autoComplateHeaderTitle {
    display: flex;
    font-size: 15px;
    font-weight: 600;
}
.autoComplateHeaderTitle .headerData{
    margin: 0 10px;
    width: 180px;
}
.search-box{
    left: -57px;
    position: relative;
    top: 10px;
    z-index: 99999;
    border-radius: 19px;
    width: 20%;
    box-shadow: none;
    padding: 7px 17px;
    outline: none;
    border: 0;
    cursor: pointer;
}
.search-btn{
    position: relative;
    cursor: pointer;
    top: 10px;
    z-index: 99999;
    width: 10%;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 7px 17px;
    outline: none;
    border: 0;
    left: -33px;
    color: #ffffff;
    background-color: #f09b57 ;
}
.search-btn:focus{
    outline: none;
}

.left-select-pane > div ,.right-select-pane .head{
    padding: 1rem;
    border-bottom: 2px solid #f5f5f5;
}

.left-select-pane .text-ellipsis {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-select-pane .text-ellipsis.big {
    width: 180px;
}

.left-select-pane .item-list-container{
    height: calc(100vh - 364px);
    overflow-y: auto;
    overflow-x: hidden;
}

.left-select-pane .item-list-container .item-list {
    list-style: none;
}

.left-select-pane .item-list-container .item-list li{
    padding: 0.7rem 0;
    border-bottom: 2px solid #f5f5f5;
}
.left-select-pane .doc-list-container{
    height: calc(100vh - 232px);
    overflow-x: hidden;
}
.right-select-pane{
    border-left: 15px solid #f5f5f5;
}
.right-select-pane .doc-list-container .item-list li {
    padding: 1rem 0;
    border-bottom: 2px solid #f5f5f5;
    cursor: pointer;
    font-size: 16px;
    color: #333333;
}
.right-select-pane .doc-list-container {
    height: calc(100vh - 405px);
    overflow: auto;
}

.email-attachment .pb-11 {
    padding-bottom: 0px;
    }

  
 /* .dx-fileuploader-button:hover,.dx-button:focus, .dx-button-normal:active, .dx-button-normal:visited {
    background: transparent linear-gradient(to top left, var(--main-color-dark), var(--main-color-light)) !important;
    color: #ffffff !important;
    border-color: #ffffff !important;
    box-shadow: none !important;
    } */

.email-attachment .btn-secondary:not(:disabled):not(.disabled):active
{
    color:black;
}

.data-deletion-modal {
    max-width: 930px ;
    margin: 1.75rem auto;
}

.data-deletion-modal img{
max-width: -webkit-fill-available;
}

.right-select-pane .doc-list-container .item-list li:hover{
    color: #eb812b;
}
#gridContainer {
    height: calc(100vh - 325px);
}

.attach{
    border: 1px #ffffff solid;
    margin: 0;
    padding: 0;
  }
  
  .attach li{
    list-style: none;
  }

  .attach .delete{
      float: right;
  }