
body {
  margin: 0;
}

.bajajLogoKTM{
  background-image: url(../images/ktmLogo.svg);
  width: 155px;
  height: 40px;
    background-repeat: round;
    margin-bottom: 25px;
    margin-top: 15px;
    margin-left: 6px;

}


code { 
  font-family: 'arial' ,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.mainContainer{  
  font-weight: normal;
  font-stretch: normal;
  max-width: 450px;
  margin: auto;
  margin-top: 15px;
  padding: 0 15px;
  font-size: 14px;
    text-align: justify;

}

.mainContainer .titles{
  font-size: 15px;
}

.mainContainer .ratingContainer{
  display: flex;
  flex-grow: 10;
  margin-bottom: 8px;
  margin-top: 6%;
}
.mainContainer .ratingContainer .rateBox{
  padding: 14px 8px 8px;
  font-size: 16px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  
}


.rateBoxActive{
  background: url("../images/star.svg");
  width: 40px;
  background-repeat: round;
  vertical-align: middle;
  color: #ffffff;
}

.rateBoxOrangeK{
  background: url("../images/starRed.svg");
  width: 40px;
  background-repeat: round;
  vertical-align: middle;
  color: #ffffff;
}

.rateBoxGoldenYellow{
  background: url("../images/starGoldenYellow.svg");
  width: 40px;
  background-repeat: round;
  vertical-align: middle;
  color: #ffffff;
}

.rateBoxGreen{
  background: url("../images/starGreen.svg");
  width: 40px;
  background-repeat: round;
  vertical-align: middle;
  color: #ffffff;
}









.mainContainer .ratingLavels{
  display: flex;
  flex-grow: 10;
  margin-bottom: 5%;
}
.mainContainer .ratingLavels .lowestRate{
  flex: 1;
  text-align: left;
  font-size: 11px;
  color: #999797;
  margin-left: -10px;
}

.mainContainer .ratingLavels .highestRate{
  flex: 1;
  text-align: right;
  font-size: 10px;
  color: #999797;
  margin-right: -10px;
}
.mainContainer .container {
  display: flex;
  padding: 0;
}

.mainContainer .container ul{
  margin-left: 0;
  padding-left: 0;
}

.mainContainer .container li:nth-child(odd) {
  padding: 6px;
  flex-grow: 5;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  font-size: 12px;
  color:#ffffff;
  margin-bottom: 15px;
  
    list-style: none;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

.mainContainer .container li:nth-child(even) {
  padding: 6px;
  flex-grow: 5;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  font-size: 12px;
  color:#ffffff;
  margin-bottom: 15px;
 
  list-style: none;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

.mainContainer .container li:last-child{
  margin-bottom: 50px!important;
}




.selectedColK{
  background-color: #ff6600;
  color: #ffffff!important;
}


textarea{
  padding: 6px;
  font-family: sans-serif;
  flex-grow: 5;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  font-size: 15px;
  color:#ffffff;
  margin-bottom: 15px;
  width: 100%;
  background: transparent;
} 

textarea ::placeholder{
  color:#747474;
}

input[type="checkbox"] {
  background-color: transparent;
  cursor: default;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
  padding: 2px;
  height: 20px;
  width: 20px;
  vertical-align: middle;  
}

.submitBt{
  background: url("../images/buttonKTM.svg");
  background-repeat: round;  
  height: 63px;
    width: 53%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  float: right;
  letter-spacing: 1px;
}

.disableBt{
  pointer-events: none;
  opacity: 0.6;
}


.submitBtBack{
  
  background: url("../images/BackBt.svg");
  background-repeat: round;
  height: 63px;
    width: 55%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  margin-left: -6px;
  letter-spacing: 1px;

}





/* Bajaj Theme */

/* The container */
.containerB {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 1px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerB input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #2d2d2d;
}

/* On mouse-over, add a grey background color */
.containerB:hover input ~ .checkmark {
  background-color: #fff;
  border: 1px solid #2d2d2d;
}

/* When the checkbox is checked, add a blue background */
.containerB input:checked ~ .checkmark {
  background-color: #011e42;
}



/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerB input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerB .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* KTM Theme */

.submitBtKTM{
  background: url("../images/buttonKTM.svg");
  background-repeat: round;  
  height: 63px;
    width: 53%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  float: right;
  letter-spacing: 1px;
}

.KTMContent{
  color: #ffffff!important;
}
.KTM{
  background-color: #000000;
  color: #ffffff; 
  padding: 15px!important;
}




/* The container */
.containerK {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 1px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerK input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.containerK .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #000;
  border: 1px solid #fff;
}

/* On mouse-over, add a grey background color */
.containerK:hover input ~ .checkmark {
  background-color: #000;
  border: 1px solid #fff;
}

/* When the checkbox is checked, add a blue background */
.containerK input:checked ~ .checkmark {
  background-color: #ff6600;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerK input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerK .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ROUTING */
.mainLink{
  padding: 0;
  margin: 0;
}
.mainLink a{
  text-decoration: none;
}
.mainLink li{
 list-style: unset;
 display: inline-block;
}
.nav-link{
  float: left;
  margin: 4px;
  background: #ff6600;
  border-radius: 6px;
  padding:6px 8px;
  color: #ffffff;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ff6600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff6600;
}




input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* .ktmSwitch .react-switch-bg {
  background: #ff6600!important;
  height: 26px!important;
} */



.react-switch-handle{
  width: 24px!important;
  height: 24px!important;
}


.btContainer{
  display: flex;
  justify-content: space-between;
  margin-right:0; 
  margin-left:0;
  position: absolute;
  bottom: 20px;
  width: 100%;
height: 65px;


  /* height:55px;
  margin-right:-15px; 
  margin-left:-15px;
  margin-bottom:20px; 
  right:0;
  width:100%  */
}

.topBorder{
  border-bottom: 1px solid #999797;
  margin-bottom: 15px;;
}

.thanksTxt{
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  padding: 0 20%;
  margin-top: 25px;
}

.thanksTxtBajaj{
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  padding: 0 20%;
 font-weight: bold;
}



.thanksTxtSmall{
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 0 10%;
  margin-top: 25px;
  padding-bottom: 20px;
}

.imgSmileBigGreyK{
  background: url("../images/smileBigGrey.svg");
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}


.imgSmileBigK{
  background: url("../images/smileAverageFirstKTM.svg");
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.imgSadBigK{
  background: url("../images/sadBigKTM.svg");
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.imgSmileAverageK{
  background: url("../images/smileAverageKTM.svg");
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.imgSmileHappyK{
  background: url("../images/smileBigKTM.svg");
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.showroomAccessibili{
  /* background-image: url(../images/ShowroomAcceIco.svg); */
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
}

