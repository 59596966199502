.designer-container {
    overflow: hidden !important;
    background-color: #ddd;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    padding: 25px 35px 11px 35px;
}
.lineelips {
    /* text-overflow: ellipsis  !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space:unset; */
}

.dx-popup-title.dx-toolbar .dx-toolbar-label{
  color: #000!important;
  padding-top: 5px!important;
}


.dx-dialog-message {
    padding: 10px 10px 5px 10px !important;
}

/*SCROLL BAR START*/

.scrollbar {
    height: 457px;
    overflow-y: auto;
    margin-bottom: 8px;
}

.force-overflow {
    min-height: 386px;
}

/*
 *  STYLE 1
 */

#style-1::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    border-radius: 8px;
    background-color: #ffffff;
}

#style-1::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c0c0c0;
}

/*
/*SCROLL BAR END*/

/*CARD A START*/

.CardA .title {
    padding:0 3px 3px 3px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.CardA .listBox {
    border: 2px solid #ddd;
    border-radius: 8px;
    min-height: auto;
    position: relative;
    width: 92%;
    padding: 20px;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
}

.CardA .listBox .rtbox {
    float: right;
    width: 80px;
    height: 40px;
}

.CardA .imgBox {
    /* background-image: url('./styles/bokeh-cover-bg.jpg');; */
    height: 72px;
    width: 120px;
    position: absolute;
    right: -30px;
    top: -25px;
    border-radius: 8px;
}

.CardA ul {
    margin: 0;
    padding: 0;
}

.CardA li {
    list-style: none;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

/*CARD A END*/

/*CARD A Grid START */

.gridPanel .CardA {
    margin-bottom: 8px;
}

.gridPanel .CardA .title {
    padding:0 3px 3px 3px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.gridPanel .CardA .listBox {
    border: 2px solid #ddd;
    border-radius: 8px;
    min-height: auto;
    position: relative;
    width: 84%;
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 10px;
    line-height: 12px;
}

.gridPanel .CardA .listBox .rtbox {
    float: right;
    width: 34px;
    height: 31px;
}

.gridPanel .CardA .imgBox {
    background-image: url("../../assets/img/bokeh-cover-bg.jpg");
    height: 50px;
    width: 50px;
    position: absolute;
    right: -12px;
    top: -15px;
    border-radius: 8px;
}

.gridPanel .CardA ul {
    margin: 0;
    padding: 0;
}

.gridPanel .CardA li {
    list-style: none;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

/*CARD A Grid END*/

/*CARD B START*/

.CardB .title {
    padding:0 3px 3px 3px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.CardB .listBox {
    border: 2px solid #ddd;
    border-radius: 8px;
    min-height: auto;
    padding: 20px;
    cursor: pointer;
    font-size: 12px;
    line-height: 22px;
}

.CardB .imgBox {
    background-image: url("../../assets/img/bokeh-cover-bg.jpg");
    height: 72px;
    width: 120px;
    position: absolute;
    right: -30px;
    top: -25px;
    border-radius: 8px;
}

.CardB ul {
    margin: 0;
    padding: 0;
}

.CardB li {
    list-style: none;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

/*CARD B END*/

/*CARD B Grid START */

.gridPanel .CardB {
    margin-bottom: 8px;
}

.gridPanel .CardB .title {
    padding:0 3px 3px 3px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.gridPanel .CardB .listBox {
    border: 2px solid #ddd;
    border-radius: 8px;
    min-height: auto;
    position: relative;
    width: 90%;
    padding: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 10px;
    line-height: 12px;
}

.gridPanel .CardB .imgBox {
    background-image: url("../../assets/img/bokeh-cover-bg.jpg");
    height: 50px;
    width: 50px;
    position: absolute;
    right: -12px;
    top: -15px;
    border-radius: 8px;
}

.gridPanel .CardB ul {
    margin: 0;
    padding: 0;
}

.gridPanel .CardB li {
    list-style: none;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}

/*CARD B Grid END*/

/*Third Box START*/

.listBoxnoCurve {
    cursor: pointer;
}

.listBoxnoCurve {
    border: 1px solid #ddd;
    background-color: #fff;
    position: relative;
    margin-bottom: 25px;
}

.listBoxnoCurve .title {
    background-color: #eb812a;
    padding: 11px 20px 11px 16px;
    text-transform: uppercase;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
}

.listBoxnoCurve .title2 {
    padding: 8px 20px 8px 16px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #ddd;
    border-bottom: 0;
    margin-top: 10px;
    font-size: 15px;
    color: #eb812a;
    font-weight: bold;
    text-transform: uppercase;
}

.listBoxnoCurve .inMarg {
    padding: 20px;
    font-size: 14px;
}

.listBoxnoCurve .listBox3 {
    border: 1px solid #ddd;
    border-radius: 0 0 8px 8px;
    min-height: auto;
    position: relative;
    padding: 20px;
    font-size: 12px;
    background-color: #f2f2f2;
}

.listBoxnoCurve .listBox3 ul {
    margin: 0;
    padding: 0;
}

.listBoxnoCurve ul {
    margin: 0;
    padding: 14px !important;
}

/*Third Box END*/

/*Third Box START Grid Panel*/

.gridPanel .listBoxnoCurve {
    cursor: pointer;
}

.gridPanel .listBoxnoCurve {
    border: 2px solid #ddd;
    background-color: #fff;
    position: relative;
    margin-bottom: 50px;
    font-size: 10px;
}

.gridPanel .listBoxnoCurve .title {
    background-color: #eb812a;
    padding: 8px 4px 8px 6px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
}

.gridPanel .listBoxnoCurve .title2 {
    padding: 8px 4px 8px 4px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #ddd;
    border-bottom: 0;
    margin-top: 10px;
    font-size: 11px;
    color: #eb812a;
    font-weight: bold;
    text-transform: uppercase;
}

.gridPanel .listBoxnoCurve .inMarg {
    padding: 8px;
    font-size: 10px;
}

.gridPanel .listBoxnoCurve .listBox3 {
    border: 1px solid #ddd;
    border-radius: 0 0 8px 8px;
    min-height: 60px;
    position: relative;
    padding: 7px;
    font-size: 10px;
    background-color: #f2f2f2;
}

.gridPanel .listBoxnoCurve .listBox3 ul {
    margin: 0;
    padding: 0;
}

.gridPanel .listBoxnoCurve ul {
    margin: 0;
    padding: 8px !important;
}

/*Third Box Grid Panel END*/

/*DELETE ICON HEADER*/

.htopInn {
    /* background-color: #f8dac1; */
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 0.6px;
}

.editIcon {
    float: right;
    margin-top: -23px;
    margin-right: 8px;
    color: #fff;
}

.editIcon {
  display: none;
}

.editIcon img{
  width:auto!important;
  height: auto!important;
}



.CardA:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.CardB:hover  .editIcon {
  display: block !important;
  cursor: pointer;
  margin-top: -1px;
}

.listBoxnoCurveA:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.containerInnC:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.inFo:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.inFotwo:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.usrDef:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.usrDefTwo:hover  .editIcon {
  display: block !important;
  cursor: pointer;
}

.usrDefThree:hover .editIcon {
  display: block !important;
  cursor: pointer;
}

/* .htopInn:hover + .editIcon {
    display: block !important;
} */

/* .editIcon:hover {
    display: block !important;
} */

.editIcon .fa-trash-o,
.fa-clone,.fa-arrows {
  cursor: pointer;
  margin-left: 20px;
}

.fa-edit{
      cursor: pointer;
    float: right;
    font-size: 20px;
}

.fa-arrows{
  background-image: url("./../../assets/img/moveIco.png");
  background-repeat: no-repeat;
  width: 21px;
  height: 20px;
}

.fa-arrows:before{
  content:"";
}


.fa-trash-o{
  background-image: url("./../../assets/img/deleteIco.png");
  background-repeat: no-repeat;
  width: 15px;
  height: 19px;
}

.fa-trash-o:before{
  content:"";
}

.fa-trash-o:hover {
    color: #7a3700;
  }
  
  .fa-clone{
    background-image: url("./../../assets/img/edit_ico.png");
    background-repeat: no-repeat;
    width: 18px;
    height: 19px;
  }
  .fa-clone:before{
    content:"";
  }
  .fa-clone:hover {
    color: #7a3700;
  }

/*DELETE ICON HEADER END*/

/*ASSETS*/
.containerMain {
    min-width: 1000px;
    /* padding: 20px 0; */
}

.mainhd {
    background-color: #ffffff;
    padding: 5px;
    padding-right: 345px;
    padding-left: 20px;
}
  
    .mainhd button {
        border: 1px solid #eb812a;
        padding: 3px 15px;
        color: #eb812a;
        background-color: transparent;
        float: right;
        margin-right: 10px;
        border-radius: 3px;
        margin-top: 7px;
        cursor: pointer;
    }
  
    .mainhd button:hover {
        background-color: #ffb272;
        color: #fff;
    }
  

.asstes {
    width: 450px;
    float: left;
    -webkit-transition: width .30s;
    -o-transition: width .30s;
    transition: width .30s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.asstes .dx-accordion{
  margin: 0!important;
}

.asstes .dx-accordion-item{
    box-shadow: none!important;
}

.asstes .dx-accordion-item-title{
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 12px 15px;
    height: 42px;
}

.asstes .dx-accordion-item{
    border: 0;
    margin-bottom: 9px;
  }
  
  .asstes .dx-accordion-item-body {
    padding: 3px 0 12px;
    font-size: 12px;
  }

  .asstes .dx-accordion-item-opened > .dx-accordion-item-title {
    background-color:#eb812a;
    color: #fff;
    height: 42px!important;
    padding: 12px 15px;
  }
  
  .asstes  .dx-accordion-item-title .dx-accordion-item-title-caption{
    top:12px!important;
    left: 15px;
  }

  

  .asstes .dx-accordion-item-opened > .dx-accordion-item-title .dx-accordion-item-title-caption{
      top:12px!important;
      color: #fff!important;
      left: 15px;
      height: 42px!important;
  }

  .asstes .dx-accordion-item-opened.dx-state-hover > .dx-accordion-item-title {
    background-color:#eb812a;
  }
  
  .asstes .dx-accordion-item-opened > .dx-accordion-item-title:before {
    content: "\F014";
    color: #fff;
    top:6px!important;
    right: 15px!important;
  }

  .asstes .dx-accordion-item-title:before {
    top:6px!important;
    right: 15px!important;
  }


.containerMain .leftPan {
    background-color: #ffffff;
    /* position: absolute; */
    margin-bottom: 40px;
    width: 100%;
    min-height: 450px;
}

.containerMain .leftPan .header {
    border-bottom: 2px solid #eb812a;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    height: 50px!important;
}

.containerMain .leftPan .header .title {
    font-size: 18px;
    color: #eb812a;
    float: left;
    padding: 5px;
    padding-left: 0;
}

.containerMain .leftPan .header .openBtn {
    background: #fff;
    border: none;
    font-size: 20px;
    cursor: pointer;
    float: right;
    color: #a0a0a0;
    padding: 3px 0 0 8px;
}

.containerMain .leftPan .header .openBtn:hover {
    color: #eb812a!important;
}

.containerMain .leftPan .header .openBtn .fa-search{
    background-image: url('./../../assets/img/search.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
  }
  
  .containerMain .leftPan .header .openBtn .fa-search:before{
    content: "";
  }
  
  .containerMain .leftPan .header .openBtn .fa-th-list{
    background-image: url('./../../assets/img/list.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
  }
  
  .containerMain .leftPan .header .openBtn .fa-th-list:before{
    content: "";
  }
  
  .containerMain .leftPan .header .openBtn .fa-plus{
    background-image: url('./../../assets/img/addnew.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
  }
  
  .containerMain .leftPan .header .openBtn .fa-plus:before{
    content: "";
  }
  
  
  
  .containerMain .leftPan .header .nav {
    position: relative;
    padding: 9px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .containerMain .leftPan .content {
    padding: 20px;
  }
  
  .containerMain .leftPan .content ul {
    margin: 0;
    padding: 0;
  }
  
  .containerMain .leftPan .content ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

    
  .containerMain .leftPan .content .listPanel li:hover .listBox,
  .imgBox {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  
  .containerMain .leftPan .content .listPanel li .listBox2 {
    border: 2px solid #ddd;
    border-radius: 8px;
    min-height: 100px;
    position: relative;
    padding: 14px;
    margin-bottom: 50px;
  }
  
  .containerMain .leftPan .content .gridPanel li {
    list-style: none;
    width: 48%;
    float: left;
    margin-bottom: 10px;
  }
  
  .containerMain .leftPan .content .gridPanel li .gridBox {
    border: 2px solid #ddd;
    border-radius: 8px;
    height: 71px;
    position: relative;
    padding: 14px;
  }
  

  .containerMain .leftPan .content .gridPanel li:nth-child(odd) {
    margin-right: 5px;
  }
  
  .containerMain .leftPan .content .gridPanel li:nth-child(even) {
    margin-left: 5px;
  }
  
  .containerMain .leftPan .content .gridPanel li .gridBox .imgBox {
    background-image: url("../../assets/img/bokeh-cover-bg.jpg");
    height: 50px;
    width: 50px;
    background-color: #a0a0a0;
    position: absolute;
    right: -7px;
    top: -7px;
    border-radius: 8px;
  }
  
  .textDeco {
    color: #a0a0a0;
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 5px;
  }
  

/*SEARCH*/

.openBtn:hover {
    color: #ccc;
  }
  
  .overlay {
    width: 100%;
    position: absolute;
    background-color: #fff;
  }
  
  .overlay .fa-search{
    background-image: url('./../../assets/img/search.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
  }
  
  .overlay .fa-search:before{
    content: "";
  }
  
   .overlay .closebtn {
    position: relative;
    font-size: 39px;
    cursor: pointer;
    color: black;
    background-color: #fff;
    padding: 0 15px 0 0;
    float: right;
  }
  
  .overlay .closebtn:hover {
    color: #ccc;
  } 
  
   
  .overlay input[type="text"] {
    font-size: 12px;
      border: none;
      float: left;
      width: 73%;
      background: white;
      padding: 16px 0px;
      font-weight: bold;
  }
  
  .overlay input[type="text"]:hover {
    background: #ffffff;
  }
  
  .overlay input:focus {
    border: 0;
  }
  
  .overlay button {
    float: left;
    padding: 10px 12px 0 20px;
    background: #fff;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }
  
  .overlay button:hover {
    background: #fff;
    color: #ccc;
  }

/*MOBILE ARTBOARD*/
.artBoardmainMobile {
    width: 360px !important;
    margin: 0 auto;
    float: none !important;
    margin-left: auto !important;
    margin-top: 20px;
  }

  .externalPreview .artBoardmainMobile {
    width: 100% !important;
    margin: 0 auto;
    float: none !important;
    margin-left: auto !important;
    /* margin-top: 20px; */
  }

  .externalPreview .artBoardmainMobile .scrollbarEx{
    height: 100vh!important;
  }


  
  .artBoardmainMobile .dx-texteditor.dx-editor-underlined .dx-texteditor-input{
    padding: 7px 20px !important;
  }

  .artBoardmainMobile .dx-list-search{
    padding: 5px 18px;
  }
  
  .artBoardmainMobile .containerInnC .box .inBox {
    border-right: 1px solid #ddd;
    padding: 3px;
    height: 42px;
    padding-left: 5px;
  }
  
  .artBoardmainMobile .containerInnC .box .inBox .text {
    float: left;
    font-size: 10px;
    /* width: 62px!important; */
    margin-top: 5px;
  }
  
  .artBoardmainMobile .header {
    height: 50px !important;
  }
  
  .artBoardmainMobile .htop {
    /* background-color: #7a3700 !important; */
    padding: 6px 20px !important;
    color: #fff !important;
    /* font-size: 14px; */
    /* height: 16px !important; */
  }
  
  .artBoardmainMobile .IcoHeader {
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
    padding-top: 6px !important;
  }
  
  .artBoardmainMobile .dx-scrollable .dx-layout-manager{
    padding-right: 0!important;
  }
  
  
  .artBoardmainMobile .Ctime {
    margin: 0 auto !important;
    width: 46% !important;
    text-align: center !important;
    float: left !important;
  }
  
  .artBoardmainMobile .content {
    padding: 20px !important;
  }
  
  .artBoardmainMobile .scrollbar {
    /* float: left !important; */
    height: 460px !important;
    overflow-y: auto !important;
    margin-bottom: 8px !important;
  }
  
  .artBoardmainMobile .scrollbarEx {
    /* float: left !important; */
    height: 550px !important;
    overflow-y: auto !important;
    margin-bottom: 8px !important;
  }


  .artBoardmainMobile .content .CardA .imgBox {
    right: -45px !important;
  }
  
  .artBoardmainMobile .CardA .listBox {
    /* width: 86.444% !important; */
    width:265px !important
  }
  
  .artBoardmainMobile .CardB .listBox {
    width: 100% !important;
  }
  
  .artBoardmainMobile .listBoxnoCurve {
    width: 100% !important;
  }

/*ARTBOARDS*/

.artBoardmain {
  width: 468px;
  margin-left: 50px;
  float: left;
}

.artBoardmain .rightPan {
  background-color: #ffffff;
  margin-bottom: 40px;
  min-height: 450px;
}

.artBoardmain .header {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  height: 52px!important;
  background-color: #eb812a;
}

.artBoardmain .IcoHeader {
  padding-top: 2px;
}

.artBoardmain .IcoHeader .hdTitle{
  text-align: center;
    width: 310px;
    float: left;
    height: 16px;
    text-align: middle;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
}
.artBoardmain .IcoHeader .fa-bars{
  background-image: url("./../../assets/img/threeline.png");
  width: 25px;
  height: 19px;
  background-repeat: no-repeat;
}
.artBoardmain .IcoHeader .fa-bars:before{
  content: "";
}

.dx-icon-close {
  color: #333333!important;
}

.artBoardmain .header .htop {
  /* background-color: #7a3700;
  padding: 5px 20px;
  height: 18px; */
  color: #fff;
}

.artBoardmain .header .fa-search {
  color: #fff;
  float: right;
  background-image: url('./../../assets/img/searchwhite.png');
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  margin-top: 10px;
}
.artBoardmain .header .fa-search:before{
  content: "";
}



.artBoardmain .header .fa-bar {
  color: #fff;
  float: left;
  padding: 10px;
  font-size: 38px;
}

.artBoardmain .header .lbar {
  font-size: 28px;
  margin: 10px;
  float: left;
}

.artBoardmain .header .fa-angle-left {
  color: #fff;
  float: left;
  padding: 0px;
  font-size: 38px;
}

.artBoardmain .header .lIcon {
  float: left;
}

.artBoardmain .header .lIcon i {
  vertical-align: middle;
  margin-top: -3px;
}

.artBoardmain .header .lIcon .fa-circle {
  font-size: 7px;
  margin-right: 3px;
}

.artBoardmain .box {
  border: 1px solid #c0c0c0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  height: 50px;
  padding: 3px;
}

.artBoardmain .box .inBox {  
  border-right: 1px solid #ddd;
  padding: 4px;
  height: 42px;
}

.artBoardmain .box .inBox .ico {
  float: left;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;
}

.artBoardmain .box .inBox .text {
  float: left;
  font-size: 12px;
  /* width: 77%!important; */
}

.artBoardmain .header .Ctime {
  margin: 0 auto;
  width: 60%;
  text-align: center;
  float: left;
}

.artBoardmain .header .RIcon {
  float: right;
}

.artBoardmain .content {
  padding: 0 20px;
  height: 455px;
}

.artBoardmain .content ul {
  margin: 0;
  padding: 0;
}

.artBoardmain .content ul li {
  /* margin: 0;
  padding: 0;
  list-style: none; */
}

.artBoardmain .content .containerInn {
  border: 2px dashed rgba(0, 0, 0, 0.16);
  color: #a0a0a0;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.artBoardmain .force-overflow:hover .containerInn{
  border: 2px dashed #eb812a
}

.artBoardmain .content .containerInn:focus{
  border: 2px dashed #eb812a
}

.artBoardmain .imgf{
  width: 100%;
}



.containerMain .leftPan .content ul li:active{
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
}

 .containerInnC {
  border: 0;
  /* margin-bottom: 20px; */
  cursor: pointer;
}

.containerInnC .title {
  padding: 8px 20px 8px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: -2px;
  background-color: #eb812a;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
}

.containerInnC .box {
  border: 1px solid #c0c0c0;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  position: relative;
  height: 50px;
  padding: 3px;
  background: #fff;
}

.containerInnC .box .inBox {
  border-right: 1px solid #ddd;
  padding: 3px;
  height: 42px;
  padding-left: 5px;
}

.containerInnC .box .inBox .ico {
  
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
  margin-top: 2px;
  margin-right: 8px;
  float: left;
  font-size: 15px;
}

.containerInnC .box .inBox .text {
  float: left;
  font-size: 12px;
  /* width: 91px; */
  margin-top: 5px;
}


.artBoardmain .content .listPanel ul {
  padding: 0;
  list-style: none;
}

.artBoardmain .content .CardA .imgBox {
  background-image: url("../../assets/img/bokeh-cover-bg.jpg");
  height: 90px;
  width: 150px;
  position: absolute;
  right: -76px;
  top: -25px;
  border-radius: 8px;
}
.artBoardmain .content .CardA .listBox {
  min-height: auto;
  width: 82.5%;
}

.artBoardmain .content .CardA .listBox .rtbox {
  float: right;
  width: 85px;
  min-height: 55px;
}

.artBoardmain .content .CardB .listBox {
  min-height: auto;
  /* width: 90%; */
}

/*COLORED*/

.htop {
  /* background-color: #7a3700;
  
  height: 15px; */
  color: #fff;
  padding: 5px 20px;
}

/*EDITOR PANEL*/

.editorPanelMain {
  float: right;
  width: 320px;
  position: absolute;
  top: 0;
  right: 0;
  height:calc(100vh - 61px);
  box-shadow: -8px 0px 8px -6px #aaa;
}

.editorPanel {
  float: right;
  width: 320px;
  background-color: #fff;  
  position: absolute;
  top: 0;
  right: 0;
  /* height: 100vh; */
}

.fa-times {
  margin: 15px;
  font-size: 15px;
  margin-bottom: 0;
  cursor: pointer;
}

.editorPanel .dx-overlay-content {
  width: 260px !important;
}

.editorPanel .drpDwn {
  border-bottom: 1px solid #a0a0a0;
  padding: 15px 0 15px 0;
  text-align: left;
  margin-bottom: 15px!important;
}

.editorPanel .propOneInput .drpDwnTab1 {
    text-align: left;
    float: left;
    width: 49%;
}

.editorPanel .propOneInput .drpDwnTab2 {
  text-align: left;
  float: right;
  width: 49%;
}


.editorPanel .drpDwn button {
  border: 1px solid #eb812a;
  padding: 9px 15px;
  color: #eb812a;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  float: right;
}

.editorPanel .drpDwn button:hover {
  background-color: #ffb272;
  color: #fff;
}

.editorPanel .drpDwn select {
  float: left;
  width: 60%;
  margin-right: 8px;
}

.editorPanel h1 {
  font-size: 15px;
}

.editorPanel h2 {
  font-size: 14px;
  font-weight: bold;
}

.editorPanel label {
  font-size: 14px;
  color: #a0a0a0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 3px;
}

.editorPanel select {
  padding: 8px;
  color: #a0a0a0;
  width: 100%;
  cursor: pointer;
}

.editorPanel .propOne {
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
}


.editorPanel .propOneInput input {
  padding: 8px;
  color: #a0a0a0;
  width: 100%;
  border: 1px solid #a0a0a0;
}

.editorPanel .propOneInput textarea {
  padding: 8px;
  color: #a0a0a0;
  width: 100%;
  border: 1px solid #a0a0a0;
}

.editorPanel .inputfile {
  width: 82% !important;
  height: 30px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
}

.editorPanel .inputfile label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.editorPanel .fa-ellipsis-h {
  font-size: 30px;
  padding-top: 4px;
  cursor: pointer;
}

.editorPanel .propOne select {
  width: 123px;
}

.editorPanel .propTwo {
  float: left;
  margin-bottom: 15px;
  width: 64px;
}

.editorPanel .colorb {
  width: 32px;
  height: 32px;
  border: none;
}

.editorPanel .colorb select {
  background-image: url("./../../assets/img/colorIco.png");
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  font-size: 11px;
  border: 0;
  position: absolute;
}

.editorPanel .textStyle {
  width: 19px;
  float: left;
  cursor: pointer;
  margin-right: 3px;
  text-align: center;
}

.editorPanel .selectGrid {
  border: 1px solid #a0a0a0;
  padding: 0 0 0 12px;
}

.editorPanel .selectGrid select {
  border: none;
  background: transparent;
  width: 90%;
}

.editorPanel .selectGrid .fa-th-large {
  font-size: 20px;
  vertical-align: middle;
}

.textStyle:hover {
  background-color: #ddd;
}

/*MENU*/
.leftMenu {
  float: left;
  margin-right: 40px;
  height: 400px;
  margin-top: 20px;
  display: none;
}

.openNav {
  text-align: center;
  cursor: pointer;
  bottom: 0;
  position: absolute;
  width: 100%;
  color: #a0a0a0;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 60px; /* 0 width - change this with JavaScript */
  /*position: fixed; */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #fff; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /*transition: 0.5s;*/ /* 0.5 second transition effect to slide in the sidenav */
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
}

a {
  word-wrap: break-word!important;
}


/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 30px;
  color: #818181;
  display: block;  /*transition: 0.3s;*/
  cursor: pointer;
}

.sidenav .active {
  color: #eb812a;
}

.active {
  color: #eb812a;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #eb812a;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  top: 0;
  font-size: 25px;
  cursor: pointer;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  /*transition: margin-left .5s;*/
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

/*NEW CARD POPUP*/


.newCard{
  overflow: auto;
  height: 531px;
  /* padding: 3px 10px 20px 0 !important; */
  margin-right: -10px;
  margin-left: -24px;
  padding-bottom: 35px!important;
  
}

.newCard .containerInnC .title {
  padding: 8px 20px 8px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: -2px;
  background-color: #eb812a;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
}

.dx-popup-draggable .dx-popup-title{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff!important;
}

.Close{
  background: url('./../../assets/img/cross.png');
  width: 24px;
  height: 24px;
  float:right;
  font-size: 25px;
  margin-top: 19px;
  margin-left: 11px;
  margin-right: 15px;
  cursor: pointer;
}

.dx-popup-draggable .dx-popup-title button {
  border: 1px solid #eb812a;
  padding: 7px 20px;
  color: #eb812a;
  background-color: transparent;
  float: right;
  margin-right: 10px;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif!important;
}

.dx-popup-draggable .dx-popup-title button:hover {
  background-color: #ffb272;
  color: #fff;
}



.newCard .number{
  width: 10px;
  position: absolute;
  top: 55px;
  left: 17px;
  z-index: 999;
  text-align: center;
  font-size: 21px;
  color: #eb812a;
}

.newCard .number .incree{
  background-image: url("./../../assets/img/incree.png");
  width: 12px;
  height: 12px;
  margin-bottom: 5px;
  cursor: pointer;
}

.newCard .number .deincree{
  background-image: url("./../../assets/img/deincree.png");
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-top: 5px;
  cursor: pointer;
}


.header{
  height: 50px;
  

}


.newCard .CardA,.CardB,.listBoxnoCurve{
  /* margin-bottom: 7px; */
}

.newCard .CardA .imgBox{
  height: 80px;
  width: 122px;
  position: absolute;
  right: -30px;
  top: -18px;
  border-radius: 8px;
}

.newCard .CardA .listBox .rtbox {
  float: right;
  width: 78px;
  min-height: 49px;
}

.newCard ul{
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  /* justify-content: center; */
  margin:0;
}

.newCard li{
  /* flex: 1; */
  border: 1px solid gray;
  margin: 10px;
  padding: 15px 15px 10px 45px;
  position: relative;
  width: 31.3333%;
  float: left;
  height: 200px;

}

.newCard li:hover{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.newCard li .dx-checkbox-icon{
  border-color: #959595!important;
  border:1px solid;
}

.newCard li .dx-checkbox{
  position: absolute;
  top: 7px;
  left: 15px;
  z-index: 999; 
}

.newCard li .containerInnC .box .inBox .text {
  float: left;
  font-size: 11px;
  width: 43px;
  margin-top: 1px;
  margin-left: 5px;
}

.newCard li .containerInnC .box .inBox .ico{
  float: left;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  display: inline-block;

  text-align: center;
  margin-top: 6px;
  margin-right: 0px;
  font-size: 15px;
 
}

.newCard li .listBoxnoCurve .inMarg {
  padding: 13px 14px;
  font-size: 12px;
}

.newCard li  .listBoxnoCurve .listBox3{
  padding: 10px;
  font-size: 12px;
  line-height: 22px;
}

.newCard li .CardA .title{
  font-size: 15px;
}

.newCard li .CardA .listBox{
  font-size: 12px;
  padding: 13px 14px;
  line-height: 14px;
  min-height: 115px;
  background: #fff;
  border: 1px solid #ddd;
  width: 93%;
}

.newCard li .listBoxnoCurve{
  border: 1px solid #ddd;
}

.newCard li .CardB .title{
  font-size: 15px; 
}

.newCard li .CardB .listBox{
  font-size: 12px;
  padding: 13px 14px;
  line-height: 14px;
  height: 115px;
  background: #fff;
  border: 1px solid #ddd;
}

.newCard li .listBoxnoCurve .title2{
  font-size: 14px;
}
.newCard li .listBoxnoCurve .title{
  font-size: 15px;
  padding: 5px 20px 5px 16px;
}

.createbt{
  margin: 9px 0;
  float: right;
  background-color: #eb812a;
  border-radius: 4px;
  border-color: transparent;
  color: #fff;
  padding:5px 18px 4px;
  letter-spacing: 1px;
  cursor: pointer;
}
 
.createbt:hover{
  background-color:#7a3700;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.dx-popup-content {
  /* padding: 24px 0 10px!important; */
}

#accordion h1 {
  font-size: 20px;
}

#accordion h1,
#accordion p {
  margin: 0;
}

.dx-theme-material #accordion .dx-accordion-item-opened h1 {
  margin-top: 7px;
}

.options,
.selected-data {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.selected-data {
  position: relative;
  height: 36px;
}

.selected-data > .caption {
  position: relative;
  top: 5px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 115%;
}

.selected-data > .dx-widget {
  position: absolute;
  left: 140px;
  right: 20px;
  top: 20px;
}

.options > .caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}


/* NEWCARDS */


.inFo {
  /* width:100%; */
  /* margin-bottom: 15px; */
  cursor: pointer;
  
  
}

.inFo .title {
  padding: 8px 20px 8px 15px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: 10px;
  background-color:#f1f1f1;
  color:#eb812a;
  font-size:15px;
  text-transform: uppercase;
}

.inFo .listBox {
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  position: relative;
  padding: 15px;
  background-color:#fff;
}


.inFotwo {
  /* width:400px;
   */
   /* margin-bottom: 15px; */
   cursor: pointer;
  
}

.inFotwo .title {
  padding: 8px 20px 8px 15px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: 10px;
  background-color:#f1f1f1;
  color:#eb812a;
  font-size:15px;
  text-transform: uppercase;
}

.inFotwo .listBox {
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  min-height: 100px;
  position: relative;
  padding: 15px;
  background-color:#fff;
}


.usrDef {
  /* width:400px; */
  /* margin-bottom: 15px; */
  cursor: pointer;
}

.usrDef .title {
  padding: 8px 20px 8px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top:-2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
}

.usrDef .listBox {
  border: 1px solid #ddd;
  min-height: 100px;
  position: relative;
  background-color:#fff;
}

.usrDef .listBox .imgf{
  width: 100%;
  min-height: 150px;
}


.usrDef .listBoxText{
  padding: 14px;
}

  .usrDef .img {
      width:100%;
      height:200px;
      border-left: 1px transparent;
      border-right: 1px transparent
  }


  .usrDefTwo {
  /* width:400px; */
  /* margin-bottom: 15px; */
}

.usrDefTwo .title {
  padding: 8px 20px 8px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top:-2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
}

.usrDefTwo .listBox {
  border: 1px solid #ddd;
  position: relative;
  padding: 14px;
  background-color:#fff;
}

  .usrDefTwo .img {
     width:155px;
     float: right;
     margin:0 0 10px 10px; 
  }

.usrDefTwo img {
   width: 155px;
  height:124px;
}


.usrDefThree{
  /* width:400px; */
  /* margin-bottom: 15px; */
  cursor: pointer;
}

.usrDefThree .title {
  padding: 8px 20px 8px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: -2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
}

.usrDefThree .listBox {
  border: 1px solid #ddd;
  position: relative;
  padding: 15px;
  background-color:#fff;
}

  .usrDefThree .img {
     width:155px;
     float: left;
     margin:0 10px 10px 0; 
  }

.usrDefThree img {
  width: 155px;
  height:124px;
}

.newCard li .inFo {
  /* width:100%; */
  margin-bottom: 15px;
  
  
}

.newCard li .inFo .title {
  padding: 5px 20px 5px 15px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top:-2px;
  background-color:#f1f1f1;
  color:#eb812a;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}

.newCard li .inFo .listBox {
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  height: 100px;
  position: relative;
  padding: 15px;
  background-color:#fff;
}


.newCard li .inFotwo {
  /* width:400px;
   */
   margin-bottom: 15px;
   cursor: pointer;
  
}

.newCard li .inFotwo .title {
  padding: 5px 20px 5px 15px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: -2px;
  background-color:#f1f1f1;
  color:#eb812a;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}

.newCard li .inFotwo .listBox {
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  height: 100px;
  position: relative;
  padding: 15px;
  background-color:#fff;
}


.newCard li .usrDef .listBoxText {
  padding: 13px 14px;
}


.newCard li .usrDef {
  /* width:400px; */
  margin-bottom: 17px;
  cursor: pointer;
}

.newCard li .usrDef .title {
  padding: 5px 20px 5px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top:-2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}

.newCard li .usrDef .listBox {
  border: 1px solid #ddd;
  min-height: 49px;
  position: relative;
  background-color:#fff;
}

.newCard li .usrDef .img {
  width: 100%;
  height: 53px;
  border-left: 1px transparent;
  border-right: 1px transparent;
  }

  .newCard li .usrDef .imgf {
    width: 100%;
    height: 100px;
    border-left: 1px transparent;
    border-right: 1px transparent;
    }


  .newCard li .usrDefTwo {
  /* width:400px; */
  margin-bottom: 15px;
  cursor: pointer;
}

.newCard li .usrDefTwo .title {
  padding: 5px 20px 5px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top: -2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}

.newCard li .usrDefTwo .listBox {
  border: 1px solid #ddd;
  position: relative;
  padding: 15px;
  background-color:#fff;
}

.newCard li .usrDefTwo .img {
     width:100px;
     float: right;
     margin:0 0 10px 10px; 
  }

  .newCard li .usrDefTwo img {
   width: 100px;
  height:60px;
}


.newCard li .usrDefThree{
  /* width:400px; */
  margin-bottom: 15px;
  cursor: pointer;
}

.newCard li .usrDefThree .title {
  padding: 5px 20px 5px 15px;
  border: 1px solid #ddd;
  border-bottom: 0;
  margin-top:-2px;
  background-color:#eb812a;
  color:#fff;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}

.newCard li .usrDefThree .listBox {
  border: 1px solid #ddd;
  position: relative;
  padding: 15px;
  background-color:#fff;
}

.newCard li .usrDefThree .img {
     width:100px;
     float: left;
     margin:0 10px 10px 0; 
  }

  .newCard li .usrDefThree img {
  width: 100px;
  height:60px;
}


.dx-htmleditor-toolbar-wrapper {
 padding:0;
  height: 44px!important;
}

.dx-toolbar-before .dx-item{
  padding-top:0 !important;
}

.dx-popup-wrapper .dx-button.dx-button-has-text {
  min-width: 25px;
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon{
  font-size: 16px !important;
}

.dx-texteditor.dx-editor-filled .dx-placeholder:before, .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
  padding: 7px 12px 18px!important;
}

.dx-toolbar .dx-texteditor {
  width: 150px;
  height: 26px;
}

.popBorder{
  margin: 10px;
  border: 1px solid #a0a0a0;
}

.popBorderProperty{
  border: 1px solid #a0a0a0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0px;
  margin-bottom: 20px;
}

.active, .sidenav .active, .sidenav a:hover{
  Color: #eb812a;
  } 
  
  .checkSho{
    height:41px;
  }

  
 .tablecolOne{
    width: 100%;
 }
    
.tablecolTwo{
    width: 50%;
}
    
.tablecolThree{
  width: 33%;
} 

.colOnewithIcon{
  width: 90%!important;
}

.colTwowithIcon{
  width:80%!important;
}

.colThreewithIcon{
  width: 73%!important;
}

.tableEditable{
  border: 1px dashed #818181;
}

.colwithoutIcon{
  width: 98%!important;
}


.artBoardmainMobile .tablecolOne{
  width: 100%;
}
  
.artBoardmainMobile .tablecolTwo{
  width: 50%;
}
  
.artBoardmainMobile .tablecolThree{
  width: 33%;
} 

.artBoardmainMobile .colOnewithIcon{
  width: 88%!important;
}

.artBoardmainMobile .colTwowithIcon{
  width:75%!important;
}

.artBoardmainMobile .colThreewithIcon{
  width: 60%!important;
}


.containerInnC .box .inBox .infSVG{
    background: url("../../assets/img/infos.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
}

.containerInnC .box .inBox .fireSVG{
    background: url("../../assets/img/fireInf.svg");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
}

.containerInnC .box .inBox .warningSVG{
    background: url("../../assets/img/warningInf.svg");
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
}
/*CARD B WITHOUT Title START*/

.CardBNoTitle .title {
  padding:0 3px 3px 3px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.CardBNoTitle .listBox {
  border: 2px solid #ddd;
  border-radius: 8px;
  min-height: auto;
  padding: 20px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
}

.CardBNoTitle .imgBox {
  background-image: url("../../assets/img/bokeh-cover-bg.jpg");
  height: 72px;
  width: 120px;
  position: absolute;
  right: -30px;
  top: -25px;
  border-radius: 8px;
}

.CardBNoTitle ul {
  margin: 0;
  padding: 0;
}

.CardBNoTitle li {
  list-style: none;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.CardBNoTitle:hover  .editIcon {
  display: block !important;
  cursor: pointer;  
}

.CardBNoTitle .editIcon {
  float: right;
  margin-top: -20px;
  margin-right: 8px;
  color: #fff;
}

.artBoardmain .content .CardBNoTitle .listBox {
  min-height: auto;
  /* width: 90%; */
}
.artBoardmainMobile .CardBNoTitle .listBox {
  width: 100% !important;
}

/*CARD B END*/

.newCard .switchTable{
  position:relative; 
  float: left;
  width:60%;
}

.sitchTable{
  border-bottom: 1px solid #000;
  margin-top: 8px;
}
.sitchTable .inBox{
  border-top: 1px solid #000;
}

.sitchTableNew .table {
  border-collapse: collapse;
  border: 1px solid black!important;
}

.sitchTableNew th {
  border: 1px solid black!important;
}

.sitchTableNew td {
  border: 1px solid black!important;
}

.asstes .switchTable{
  position:relative; 
  float: left;
  width:55%;
}

.asstes .usrDefThree img {
  width: 150px;
  height: 124px;
}

.asstes .usrDefThree .img {
  width: 155px;
  float: left;
  margin: 0 10px 10px 0;
}

.artBoardmain .switchTable{
  position:relative; 
  float: left;
  width:57%;
}

.artBoardmain .switchTable .box {
   border:0; 
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  height: auto;
  padding: 3px;
}

.artBoardmain .switchTable  .box .inBox {
  border-right: 0;
  padding: 4px;
  height: 20px;
}

.artBoardmain .usrDefThree img {
  width: 150px;
  height: 124px;
}

.artBoardmain .usrDefThree .img {
  width: 155px;
  float: left;
  margin: 0 10px 10px 0;
}

.artBoardmainMobile .switchTable{
    position: relative;
    float: left;
    width: 67%;
    font-size: 13px;
}

.artBoardmainMobile .box .inBox .text {
  float: left;
  font-size: 11px;
}

.newCard .usrDef .listBox .imgf{
  width: 100%;
  min-height: 100px;
}

.newCard .switchTableNew{
  position: relative;

}

.asstes .switchTableNew{
  position: relative;
}

.artBoardmainMobile .switchTableNew{
  position: relative;
}

.newCard li .usrDefThree .imgNew {
  width:100%;
  float: left;
  margin:0;
  margin-bottom: 8px; 
}

.newCard li .usrDefThree .imgNew img{
  width: 100%;
  height: 47px;
}

.asstes li .usrDefThree .imgNew {
  width:100%;
  float: left;
  margin:0;
  margin-bottom: 8px; 
}

.asstes li .usrDefThree .imgNew img{
  width: 100%;
  height: 120px;
}

.artBoardmain li .usrDefThree .imgNew {
  width:100%;
  float: left;
  margin:0;
  margin-bottom: 8px; 
}

.artBoardmain li .usrDefThree .imgNew img{
  width: 100%;
  height: 155px;
}


.FullImageWidth {
  width : 100%;
}

.ImageMargin {
  margin: 0 0 5px 20px;
}

.ImageTitle {
  color: #000000;
  line-height: 20px;
}

.WithBorder {
  border: solid 1px;
}

.WithPaddings {
  border: solid 1px;
  padding: 5px 5px;
  background-color: white;
}

.SemiTransparent {
  opacity: 0.4;
}

.lineHeight_1 {
  line-height: 1.0;
}

.lineHeight_2 {
  line-height: 2.0;
}

.lineHeight_3 {
  line-height: 3.0;
}

.FitToText {
  text-align: justify;
  display: block;
}
