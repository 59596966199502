.mainServiceContainer {
    font-Family: arial;
    color: #000000;
    margin: auto;
    max-width: 450px;
    background: #FFF;
    overflow-x: hidden;
    min-height: 100vh;
    overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0.1em;
}

.ktmLogo {
    width: 55px;
    height: 18px;
}
.ktmLogo1 {
  height: 55px;
  margin-right: 3px;
}

.bajajNewLogo {
    height: 36px;
    width: 103px;
}

.headerDivKTM {
    background: #ff6600;
    height: 60px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
    max-width: 450px;
    width: 100%;
    z-index: 9999;
}

.headerDivBajaj {
    display: flex;
    position: relative;
}

.dealerInfo .dealerName {
  font-size: 16px;
  color: #FFF;
  font-family: 'BlenderPro';
  font-weight: 600;
  letter-spacing: normal;
}

.dealerInfo .showroomLocation {
  font-size: 12px;
  color: #FFF;
  font-family: 'BlenderPro';
  font-weight: 600;
}

.customerName {
    text-align: center;
    font-family: 'BlenderPro';
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    color: #000000;
}

.details {
    margin: 15px 0 0;
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.mainContainerRating {
  margin: 16px;
}

.mainContainerRating .ratingContainer{
    display: flex;
    flex-grow: 10;
    margin-bottom: 8px;
    margin-top: 6%;
}
.mainContainerRating .ratingContainer .rateBox{
    padding: 10px 2px 6px;
    font-size: 16px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    vertical-align: middle;
    font-family: 'TradeGothicLTCom-Bold';
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #000000;
    background-color: #ddd;
    margin-right:4px
}
.mainContainerRating .ratingContainer .rateBox:last-child{
  margin-right:0px
}
.likeUnlineText {
    font-family: 'BlenderPro';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.25px;
    color: #000000;
}

.thumbsDown {
    width: 18px;
  
   
}

.language {
    font-family: 'KohinoorDevanagari';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.25px;
    color: #0066b3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.each-language {
    margin: 10px;
    cursor: pointer;
}

/* /////////////////////////////   QUESTIONS ////////////////////////////////////////////////////////// */
  .card {
    max-width: 100%;
    height: auto;
    margin: 16px 16px 0px;
    padding: 10px 0 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    position: unset;
    min-height: 70px;
  }

  .cardDisableTask {
    max-width: 100%;
    height: auto;
    margin: 16px 16px 0px;
    padding: 10px 0 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    position: unset;
    pointer-events: none;
    opacity: 0.7;
    min-height: 70px;
  }
  
  .container {
    padding: 2px 8px 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .card .container img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }

  .questionHeader {
    margin: 5px 9px 5px;
    font-family: 'BlenderPro';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: center;
  }

  .displayNameKTM {
    margin: 0 10px 2px 0;
    font-family: 'BlenderProHeavy';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .displayName {
    margin: 0 10px 2px 0;
    font-family: 'TradeGothicLTCom-Bold';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .displaySubheader {
    margin: 2px 0 0;
    opacity: 0.5;
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #000000;
  }

  .liner {
    margin: 10px 0 15px;
    opacity: 0.3;
    border: solid 1px #979797;
  }

  .header-container {
      margin-left: 16px;
  }

  .driverMainBorder {
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #000000;
    margin: 0 8px 8px 0;
    padding: 4px 12px;
    border-radius: 12px;
    border: solid 1px #7f7f7f;
    display: inline-block;
    cursor: pointer;
  }
  
  .driverMainBorderSelected {
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #FFF;
    margin: 0 8px 8px 0;
    padding: 5px 13px;
    display: inline-block;
    border-radius: 12px;
    /* background-color: #0066b3; */
    cursor: pointer;
  }
  .drivermainContainer {
      margin: 16px;
  }

  .suggetions {
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    margin: 0 16px;
  }

  .textareaNew {
    height: 155px;
    border-radius: 6px;
    background-color: #ffffff;
    resize: none;
    border: solid 1px grey;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: auto;
    outline: none;
    padding: 10px;
    opacity: 0.5;
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #000000;
  }

  .countCharNew {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border: solid 1px grey;
    border-top: none;
    margin-top: -20px;
    border-radius: 6px;
    height: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 5px;
    opacity: 0.5;
  }

  .callbackCheckbox {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 10px;
  }

  .submitbutton {
    margin: 40px 30px 40px;
  }

  .submitbutton .MCButton {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    background-color: #0066b3;
    font-family: 'BlenderPro';
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    border: none;
  }

  .submitbutton .KTMButton {
    height: 40px;
    width: 100%;
    background-color: #373f43;
    font-family: 'BlenderPro';
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    border: none;
  }

  .copyrightNew {
    margin: 4px 14px 40px;
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #5d5d5d;
  }

  .privacyContainer {
    display: flex;
    justify-content: center;
  }

  .privacyContainer div {
    margin: 0 16px 0 0;
    font-family: 'TradeGothicLTCom-Cn18';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #5d5d5d;
    cursor: pointer;
  }

  /* /////////////////////////////////////////// THANK YOU SCREEN /////////////////////////////////////////////////////////////// */

  .thanksContainer {
    color: #000000;
    margin: auto;
    max-width: 450px;
    background: #FFF;
    overflow-x: hidden;
    min-height: 100vh;
    overflow-y: auto;
    background: rgb(0, 93, 177);
    background-image: url(../../../assets/images/bajajThanksScreenNew.png);
    background-repeat: no-repeat;
    background-size: 100% 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .thanksContainerIBU {
    color: #000000;
    margin: auto;
    max-width: 450px;
    background: #FFF;
    overflow-x: hidden;
    min-height: 100vh;
    overflow-y: auto;
    background: rgb(0, 93, 177);
    background-image: url(../../../assets/images/ibuThankScreenNew.png);
    background-repeat: no-repeat;
    background-size: 100% 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .thanksContainerKTM {
    color: #000000;
    margin: auto;
    max-width: 450px;
    background: #FFF;
    overflow-x: hidden;
    min-height: 100vh;
    overflow-y: auto;
    background-image: url(../../../assets/images/ktmThankScreenNew.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .headerThanks {
    /* margin-top: 16px; */
    display: flex;
    align-items: center;
    background: #FFF;
    height: 60px;
    /* padding: 0 15px; */
    /* padding-left: 15px; */
    max-width: 450px;
    width: 100%;
    z-index: 9999;
    font-size: 15px;
  }

  .headerThanks button {
    border: 2px solid #0052a1;
    border-radius: 10px;
    height: 25px;
    color: #0052a1;
    width: 75px;
    margin-left: auto;
    font-size: 13px;
    font-weight: bold;
    font-family: 'BlenderPro';
    margin-top: 2px;
  }

  .headerThanks .headerText {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'BlenderPro';
  }

  .headerThanks .headerThanksbajajLogo {
    /* margin-left: 30px; */
    height: 40px;
    /* width: 40px; */
  }

  .thanksText {
    height: 65px;
    background-color: #ffffff;
    font-family: 'BlenderPro';
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1px;
    color: #131415;
    font-family: 'BlenderPro';
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10%;
  }

  /* ////////////////////////////////////// Link Invalid Container /////////////////////////////////////////////////////// */

  .invalidLinkMainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;
  }

  .invalidLinkMainContainer .linkInvalidText {
    font-family: 'BlenderPro';
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
    text-align: center;
    color: #131415;
    margin-top: 8px;
  }

  .feedbackSubmitImage {
      color: #000000;
      margin: auto;
      background: #FFF;
      overflow-x: hidden;
      overflow-y: auto;
      background-image: url(../../../assets/images/FeedbackSubmittedMC.PNG);
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-top: 28px;
  }

  
.feedbackSubmitImageKTM {
    color: #000000;
    margin: auto;
    background: #FFF;
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url(../../../assets/images/FeedbackSubmitKTM.png);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 28px;
}

.feedbackSubmitImageCV {
    color: #000000;
    margin: auto;
    background: #FFF;
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url(../../../assets/images/IbuAlreadySubmitimg.png);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 28px;
}


.expireContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-driver-enter {
  max-height: 0px;
  transition: max-height 1s ease;
  overflow: hidden;
}

.question-driver-enter.question-driver-enter-active {
  height: auto;
  max-height: 450px;
}

.question-driver-leave {
  max-height: 600px;
  transition: max-height 1s ease;
}

.question-driver-leave.question-driver-leave-active {
  overflow: hidden;
  max-height: 0px; 
}

