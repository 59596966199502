 
  .hdColumn .divRound {
      float: left;
      border: 1px solid #ddd;
      border-radius: 24px;
      font-size: 12px;
      min-width: 135px;
      padding: 10px;
      margin: 5px 15px;
  }
  
  .hdColumn .headerTitle {
      float: left;
      font-size: 14px;
      min-width: 135px;
      padding: 10px;
      margin: 5px 15px;
      font-weight: bold;
      
  }
  
  .dataContainer .searchData {
      float: left;
      font-size: 12px;
      min-width: 140px;
      padding: 10px;
      margin: 0 10px;
      text-overflow: ellipsis;
  }
  
  .custom-container{
      margin-left: -10px;
  }
  
  .custom-container .dx-list-item-content{
      padding: 0!important;
  }
  .custom-container .dx-item{
      padding: 5px 0!important;
      border-bottom: 1px solid #ddd;
  }
  
  .custom-container .dx-item:first-child{
   position: fixed;
    top: 0;
    background: #fff;
    z-index: 1000;
    width: 500px;
    -webkit-box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 12px -10px rgba(0, 0, 0, 0.08);
  }
  
  .custom-container .dx-item:nth-child(2){
      margin-top:85px;
  }
  